import Ionicons from "../Svg/Ionicons";
import { Pressable, View } from "react-native";
import { useColorScheme } from "tailwindcss-react-native";

const SupportButton = () => {
  const { colorScheme } = useColorScheme();

  return (
    <View className="absolute top-5 left-5">
      <Pressable
        className="flex items-center justify-center bg-gray-100 rounded dark:bg-grayish-500 w-11 h-11"
        onPress={() => {
          alert("Hello, Support!");
        }}
      >
        {colorScheme === "dark" ? (
          <Ionicons name="logo-whatsapp-white-5" size={20} color="white" />
        ) : (
          <Ionicons name="logo-whatsapp-black-5" size={20} color="black" />
        )}
      </Pressable>
    </View>
  );
};

export default SupportButton;
