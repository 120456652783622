import { Picker } from "@react-native-picker/picker";
import { StyleSheet, Platform } from "react-native";
import { useColorScheme } from "tailwindcss-react-native";
import { useLocation } from "react-router-dom"; // for web only

export default function PickerAgeRange({
  selectedAgeRange,
  setSelectedAgeRange,
}: {
  selectedAgeRange: string;
  setSelectedAgeRange: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { colorScheme } = useColorScheme();
  const location = Platform.OS === "web" ? useLocation() : null;
  return (
    <Picker
      style={
        Platform.OS !== "web"
          ? null
          : colorScheme === "dark" && location?.pathname === "/email-signup"
          ? styles.pickerDark
          : styles.picker
      }
      selectedValue={selectedAgeRange}
      onValueChange={(itemValue, itemIndex) => {
        setSelectedAgeRange(itemValue);

        // we use alert here
        if (
          itemValue === "less than 13 years old" ||
          itemValue === "13 to 17 years old"
        ) {
          alert(
            "Children under the age of 18 must obtain approval from their parent, guardian, or person who has parental responsibility before registering. If you continue with registration on our site, we assume that parental consent has been obtained for us to process your personal information in accordance with Akta Perlindungan Data Peribadi 2010."
          );
        }
        // console.log(itemValue);
      }}
    >
      <Picker.Item
        label="Please select an option..."
        value="Please select an option..."
      />
      <Picker.Item
        label="less than 13 years old"
        value="less than 13 years old"
      />
      <Picker.Item label="13 to 17 years old" value="13 to 17 years old" />
      <Picker.Item label="18 to 20 years old" value="18 to 20 years old" />
      <Picker.Item label="21 to 25 years old" value="21 to 25 years old" />
      <Picker.Item label="26 to 30 years old" value="26 to 30 years old" />
      <Picker.Item label="31 to 35 years old" value="31 to 35 years old" />
      <Picker.Item label="36 to 40 years old" value="36 to 40 years old" />
      <Picker.Item label="41 to 45 years old" value="41 to 45 years old" />
      <Picker.Item label="46 to 50 years old" value="46 to 50 years old" />
      <Picker.Item label="51 to 55 years old" value="51 to 55 years old" />
      <Picker.Item label="56 to 60 years old" value="56 to 60 years old" />
      <Picker.Item
        label="more than 60 years old"
        value="more than 60 years old"
      />
    </Picker>
  );
}

const styles = StyleSheet.create({
  picker: {
    borderRadius: 4,
    borderColor: "#e5e7eb",
    paddingHorizontal: 3, // this has no effect in Safari browser, will go with default for that
    height: 35, // because paddingVertical does not work in Safari browser
    color: "black",
    fontSize: 14,
    backgroundColor: "#e5e7eb", // bg-gray-200
  },
  pickerDark: {
    borderRadius: 4,
    borderColor: "#353535",
    paddingHorizontal: 3, // this has no effect in Safari browser, will go with default for that
    height: 35, // because paddingVertical does not work in Safari browser
    color: "white",
    fontSize: 14,
    backgroundColor: "#353535", // bg-grayish-600
  },
});
