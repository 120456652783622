import { View, Text, Platform } from "react-native";
import { ButtonPrimary } from "../Buttons";
import DarkSwitchButton from "../Buttons/DarkSwitchButton";
import Logo from "../Svg/LogoDealsifu";
import SupportButton from "../Buttons/SupportButton";
import { Motion } from "@legendapp/motion/";
import * as Linking from "expo-linking";
import { useNavigate } from "react-router-dom"; // for web only

export default function NotFoundPage() {
  const navigate = Platform.OS === "web" ? useNavigate() : null;
  return (
    <View className="flex-col items-center justify-center h-full p-10 bg-white dark:bg-grayish-700">
      <DarkSwitchButton />
      <SupportButton />
      <Motion.View
        key="A"
        className="w-16 h-16 mb-6"
        initial={{ rotate: `${0}deg` }}
        animate={{ rotate: `${90}deg` }}
        transition={{
          type: "spring",
          damping: 20,
          stiffness: 100,
          mass: 10,
        }}
      >
        <Logo />
      </Motion.View>

      <View className="flex-col items-center justify-center w-full max-w-xs p-5 bg-gray-100 rounded dark:bg-grayish-800">
        <View className="flex-row">
          <Text className="mb-6 text-3xl font-bold text-gray-900 dark:text-white">
            404. Oops!
          </Text>
        </View>

        <Text className="mb-6 font-bold text-center text-gray-900 dark:text-white">
          Page not found.
        </Text>
        <Text className="mb-6 text-center text-gray-900 dark:text-white">
          Let us guide you back 🏠 home <br /> and make all of us happy again 😊
        </Text>

        <ButtonPrimary
          onPress={() => {
            const url = "/";
            if (Platform.OS === "web") {
              if (navigate) {
                navigate("/"); // this also result in theme following system theme instead of the dark switch button
              }
            } else {
              Linking.openURL(url); // this will result in theme following system theme instead of the dark switch button, is it even possible to go to 404 for mobile app?
            }
          }}
          buttonText="Let's go back home"
        />
      </View>
      {/* End Login Inner Container */}
    </View>
  );
}
