import { Modal, StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { useModalStore } from "../../utils/store";
import { modalAnimationType } from "../../utils/deviceInfo";

export default function AlertModal({
  modalAlertVisible,
  setModalAlertVisible,
  handleAlertModalExit,
  textPrimary,
  textSecondary,
}: any) {
  // ZUSTAND
  const setModalTwoShowing = useModalStore((state) => state.setModalTwoShowing);
  return (
    <Modal
      animationType={modalAnimationType}
      transparent={true}
      statusBarTranslucent={true}
      visible={modalAlertVisible}
      onRequestClose={() => {
        // for Android only
        // alert("Modal has been closed.");
        setModalAlertVisible(false);
        setModalTwoShowing(false); // technically not needed for Android, but we put here just for consistency
      }}
    >
      <View className="items-center justify-center flex-1 bg-black/50">
        <View
          style={styles.modalView}
          className="flex-col items-center justify-center w-64 p-10 bg-gray-100 rounded"
        >
          <View className="w-full">
            <Text className="mb-2 font-semibold">{textPrimary}</Text>
            <Text className="mb-6">{textSecondary}</Text>
          </View>
          <View className="absolute bg-green-600 p-1.5 rounded bottom-5 right-5">
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                handleAlertModalExit();
              }}
            >
              <Text className="text-center text-white">OK</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalView: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    // replace px-2 py-1
    paddingHorizontal: 6,
    paddingVertical: 3,
  },
});
