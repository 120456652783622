// https://reactnative.dev/docs/timers
// https://stackoverflow.com/questions/51695887/countdown-timer-in-react-native
// to adjust time difference when app is in the background:
// https://aloukissas.medium.com/how-to-build-a-background-timer-in-expo-react-native-without-ejecting-ea7d67478408
import { useEffect, useRef, useState } from "react";
import { Text, AppState } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { differenceInSeconds } from "date-fns";

const TIMER_SECONDS = 180 + 10; // 3 mins plus 10 seconds buffer

export default function Timer({
  setIsShowingTimer,
  timeLeft,
  setTimeLeft,
}: any) {
  let timer: any = () => {};
  const appState = useRef(AppState.currentState);
  const [elapsed, setElapsed] = useState<number>(0);

  const startTimer = () => {
    timer = setTimeout(() => {
      if (timeLeft <= 0) {
        clearTimeout(timer);
        setIsShowingTimer(false);
        setTimeLeft(TIMER_SECONDS); // reset timer value
        return false;
      }
      if (elapsed >= 1) {
        if (timeLeft - elapsed <= 0) {
          clearTimeout(timer);
          setIsShowingTimer(false);
          setTimeLeft(TIMER_SECONDS); // reset timer value
          return false;
        }
        // if timeLeft - elapsed is still > 0
        setTimeLeft(timeLeft - elapsed);
        setElapsed(0); // zerorize elapse
      } else {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);
  };

  // Giving it no second argument acts as both componentDidMount and componentDidUpdate,
  // as in it runs first on mount and then on every re-render.
  useEffect(() => {
    startTimer();
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const subscription = AppState.addEventListener(
      "change",
      handleAppStateChange
    );
    return () => subscription.remove();
  }, []);

  const recordStartBgTime = async () => {
    try {
      const now = new Date();
      await AsyncStorage.setItem("@start_bg_time", now.toISOString());
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  const handleAppStateChange = async (nextAppState: any) => {
    if (
      appState.current === "active" &&
      nextAppState.match(/inactive|background/)
    ) {
      // if we change from active to background / inactive
      // we record the starting time we go into background
      recordStartBgTime();
    } else if (
      appState.current.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      // We just became active again: recalculate elapsed time based
      // on what we stored in AsyncStorage when we started.
      const elapsed = await getElapsedTime();
      // Update the elapsed seconds state
      if (elapsed) {
        setElapsed(elapsed);
      }
    }
    appState.current = nextAppState;
  };

  const getElapsedTime = async () => {
    try {
      const startTime: any = await AsyncStorage.getItem("@start_bg_time");
      const now = new Date();
      return differenceInSeconds(now, Date.parse(startTime));
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  return <Text className="text-xs text-center text-gray-500">{timeLeft}s</Text>;
}
