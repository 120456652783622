import { useState, useRef } from "react";
import {
  Modal,
  StyleSheet,
  Text,
  Pressable,
  View,
  Platform,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useForm, Controller } from "react-hook-form";
import { emailRules } from "../FormHelper/formRules";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { supabase } from "../../utils/supabase";
import { ButtonPrimary, ButtonTextCustomOrange } from "../Buttons";
import { useModalStore } from "../../utils/store";
import { modalAnimationType } from "../../utils/deviceInfo";
import ToastDefault from "../Toast/ToastDefault";

export default function RequestResetPasswordModal() {
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const toastRef: any = useRef();

  // ZUSTAND
  const setModalShowing = useModalStore((state) => state.setModalShowing);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  // data from react-hook-form
  // data =  {email: "",.....}
  async function requestResetPassword({ email }: any) {
    setLoadingResetPassword(true);
    const { data, error } = await supabase.auth.api.resetPasswordForEmail(
      email
    );
    if (error) {
      toastRef.current.show(error.message, {
        type: "danger",
      });
    }
    if (data) {
      toastRef.current.show(
        "Please check your email to continue resetting password.",
        {
          type: "success",
        }
      );
    }
    setLoadingResetPassword(false);
  }

  return (
    <>
      <Modal
        animationType={modalAnimationType}
        transparent={true}
        statusBarTranslucent={true}
        visible={modalVisible}
        onRequestClose={() => {
          // this is for Android only
          // Alert.alert("Modal has been closed.");
          setModalVisible(false);
          setModalShowing(false);
        }}
      >
        <SafeAreaProvider>
          <SafeAreaView className="flex-1 bg-black/50">
            <TouchableWithoutFeedback
              onPress={Platform.OS !== "web" ? Keyboard.dismiss : () => {}}
            >
              <KeyboardAvoidingView
                behavior={"padding"}
                className="items-center justify-center flex-1"
              >
                <View
                  style={styles.modalView}
                  className="flex-col items-center justify-center p-10 bg-gray-100 rounded w-72"
                >
                  <Pressable
                    className="absolute top-3 right-3"
                    onPress={() => {
                      setModalVisible(false);
                      setModalShowing(false);
                    }}
                  >
                    <Ionicons name="md-close-sharp" size={30} color="black" />
                  </Pressable>

                  <View className="w-full">
                    <Text className="mb-6 text-2xl font-semibold text-gray-900">
                      Reset password
                    </Text>
                    <Controller
                      control={control}
                      rules={emailRules}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          className="p-2 mb-5 bg-gray-200 border-b-2 border-orange-500 caret-black"
                          style={Platform.select(rnw_outline_none)}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          keyboardType={"default"}
                          placeholder={"Your registered email"}
                          placeholderTextColor="gray"
                        />
                      )}
                      name="email"
                    />
                    {errors.email && (
                      <Text className="mb-5 -mt-4 text-red-700">
                        {errors.email.message}
                      </Text>
                    )}
                    <ButtonPrimary
                      onPress={handleSubmit(requestResetPassword)}
                      isDisabled={loadingResetPassword}
                      loading={loadingResetPassword}
                      buttonText="Request Reset Password"
                    />
                  </View>
                </View>
              </KeyboardAvoidingView>
            </TouchableWithoutFeedback>
          </SafeAreaView>
        </SafeAreaProvider>
        <ToastDefault ref={toastRef} />
      </Modal>

      <ButtonTextCustomOrange
        twPressable="absolute left-0"
        onPress={() => {
          setModalVisible(true);
          setModalShowing(true);
        }}
        buttonText="Forgot Password?"
      />
    </>
  );
}

const styles = StyleSheet.create({
  modalView: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});

// workaround, for react-native-web by default, it has border outline around TextInput when focused
// https://stackoverflow.com/questions/53639013/typescript-and-react-native-are-the-type-definitions-for-rn-styles-wrong
const rnw_outline_none: any = {
  web: {
    outlineStyle: "none",
  },
};
