import Svg, { Path, Circle } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */
/* initially for Path and Circle we have stroke="currentColor", we remove it to give way for tailwindcss stroke-white */
// "w-5 h-5 stroke-white", original size 20

const SunnyOutline = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    className="ionicon"
    viewBox="0 0 512 512"
    {...props}
  >
    <Path
      fill="none"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={32}
      d="M256 48v48m0 320v48m147.08-355.08-33.94 33.94M142.86 369.14l-33.94 33.94M464 256h-48m-320 0H48m355.08 147.08-33.94-33.94M142.86 142.86l-33.94-33.94"
    />
    <Circle
      cx={256}
      cy={256}
      r={80}
      fill="none"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={32}
    />
  </Svg>
)

export default SunnyOutline;
