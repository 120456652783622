import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { View, Modal, Text, Pressable, StyleSheet } from "react-native";
import { ButtonPrimary } from "../Buttons";
import { Slider } from "@sharcoux/slider";
import getCroppedImg from "./cropImage";
import { Ionicons } from "@expo/vector-icons";
import { useModalStore } from "../../utils/store";
import { modalAnimationType } from "../../utils/deviceInfo";
import { decode } from "base64-arraybuffer";
import { supabase } from "../../utils/supabase";
import { useColorScheme } from "tailwindcss-react-native";

// !!! NOTE THIS IS FOR WEB ONLY
// image will be converted to png

const AvatarCropperModalWeb = ({
  origImageBase64Url,
  origImageName,
  onUpload,
}: {
  origImageBase64Url: string;
  origImageName: string;
  onUpload: any;
}) => {
  const { colorScheme } = useColorScheme();

  const [modalVisible, setModalVisible] = useState(false);
  // ZUSTAND
  const setModalShowing = useModalStore((state) => state.setModalShowing);
  const [uploading, setUploading] = useState<boolean>(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  //const [croppedImage, setCroppedImage] = useState<any>(null);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        origImageBase64Url,
        croppedAreaPixels!,
        rotation
      );
      // console.log("done!", { croppedImage });
      //setCroppedImage(croppedImage);

      // added
      if (croppedImage) {
        const fileExt = croppedImage?.split(";")[0].split("/")[1];
        const base64ImageString = croppedImage?.split(",")[1];

        setUploading(true);
        const { error: errorUpdating } = await supabase.storage
          .from("avatars")
          .update(origImageName, decode(base64ImageString), {
            cacheControl: "3600",
            upsert: false,
            contentType: `image/${fileExt}`,
          });
        setUploading(false);

        if (errorUpdating) {
          throw errorUpdating;
        }

        // Math.random() return value between 0.000000001... to 0.9999999999..., we want to remove "0.", we use slice(2)
        const newFileName = `${Math.random().toString().slice(2)}_${new Date()
          .toJSON() // 2022-07-27T05:57:28.465Z
          .replace(/\.|:/g, "-")}`; // regex replace all occurence of period "\." OR ":" (period need to be \)

        const newFileExt = ".png";
        const newFilePath = newFileName + newFileExt;

        // we copy file to change the name of the newFile
        const { error: errorCopying } = await supabase.storage
          .from("avatars")
          .copy(origImageName, newFilePath);

        if (errorCopying) {
          throw errorCopying;
        }

        // we delete the old file with the old name
        const { error: errorDeleting } = await supabase.storage
          .from("avatars")
          .remove([origImageName]);

        if (errorDeleting) {
          throw errorDeleting;
        }

        onUpload(newFilePath);
        // once done
        setModalVisible(false);
        setModalShowing(false);
        // location.reload(); // force refresh page
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <>
      <Modal
        animationType={modalAnimationType}
        transparent={true}
        statusBarTranslucent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
          setModalShowing(false);
        }}
      >
        <View className="items-center justify-center flex-1 bg-black/50 ">
          <View
            style={styles.modalView}
            className="flex-col items-center justify-center p-10 bg-gray-100 rounded w-72"
          >
            <Pressable
              className="absolute top-3 right-3"
              onPress={() => {
                setModalVisible(false);
                setModalShowing(false);
              }}
            >
              <Ionicons name="md-close-sharp" size={30} color="black" />
            </Pressable>
            <View className="mt-2 h-52 w-52">
              <Cropper
                image={origImageBase64Url}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </View>
            <View className="w-52">
              <View className="mt-3">
                <Text>Zoom</Text>
                <Slider
                  value={zoom}
                  minimumValue={1}
                  maximumValue={3}
                  step={0.1}
                  aria-accessibilityLabel="Zoom"
                  onValueChange={(zoom) => setZoom(zoom)}
                  thumbTintColor="#ea580c"
                />
              </View>
              <View className="mt-3">
                <Text>Rotation</Text>
                <Slider
                  value={rotation}
                  minimumValue={0}
                  maximumValue={360}
                  step={1}
                  aria-accessibilityLabel="Rotation"
                  onValueChange={(rotation) => setRotation(rotation)}
                  thumbTintColor="#ea580c"
                />
              </View>
              <ButtonPrimary
                onPress={showCroppedImage}
                loading={uploading}
                buttonText="Update Avatar"
                marginTailwind="mt-3"
              />
            </View>
          </View>
        </View>
      </Modal>

      <Pressable
        className="flex items-center justify-center w-8 h-8 rounded"
        onPress={() => {
          setModalVisible(true);
          setModalShowing(true);
        }}
      >
        {colorScheme === "dark" ? (
          <Ionicons name="create-outline" size={24} color="white" />
        ) : (
          <Ionicons name="create-outline" size={24} color="black" />
        )}
      </Pressable>
    </>
  );
};

export default AvatarCropperModalWeb;

const styles = StyleSheet.create({
  modalView: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
