import { View, Text, Pressable, Platform } from "react-native";
import * as Linking from "expo-linking";
import { Ionicons } from "@expo/vector-icons";
import { useColorScheme } from "tailwindcss-react-native";
import { useLocation } from "react-router-dom"; // for web only

export default function PrivacyNotice() {
  const { colorScheme } = useColorScheme();
  const location = Platform.OS === "web" ? useLocation() : null;
  const textClassName =
    location?.pathname === "/email-signup" && colorScheme === "dark"
      ? "ml-1 text-xs italic text-white"
      : "ml-1 text-xs italic text-black";
  return (
    <View className="flex-row mt-4">
      <Ionicons name="lock-closed-outline" size={15} color="#ca8a04" />
      <Text className={textClassName}>
        We care about your data privacy - information with asterisk (*) will{" "}
        <Text className="font-bold">never</Text> be shared publicly. By signing
        up, you agree to our{" "}
        {Platform.OS === "web" ? (
          <Pressable
            className="font-bold"
            onPress={() => {
              window.open("https://forum.dealsifu.com/tos", "_blank");
            }}
          >
            <Text>terms</Text>
          </Pressable>
        ) : (
          <Pressable
            className="font-bold"
            onPress={() => {
              Linking.openURL("https://forum.dealsifu.com/tos");
            }}
          >
            <Text>terms</Text>
          </Pressable>
        )}{" "}
        and{" "}
        {Platform.OS === "web" ? (
          <Pressable
            className="font-bold"
            onPress={() => {
              window.open("https://forum.dealsifu.com/privacy", "_blank");
            }}
          >
            <Text>privacy</Text>
          </Pressable>
        ) : (
          <Pressable
            className="font-bold"
            onPress={() => {
              Linking.openURL("https://forum.dealsifu.com/privacy");
            }}
          >
            <Text>privacy</Text>
          </Pressable>
        )}{" "}
        policy.
      </Text>
    </View>
  );
}

/* 
<ButtonTextCustomDarkOrange
    twPressable="mt-4"
    twText="text-center"
    onPress={() => {
        const url = "https://forum.dealsifu.com/privacy";
        if (Platform.OS == "web") {
        window.open(url, "_blank");
        } else {
        Linking.openURL(url);
        }
    }}
    buttonText="We care about your data privacy"
/> 
*/
