export const usernameRules = {
  required: "Username is required",
  minLength: {
    value: 3,
    message: "Username must be at least 3 characters",
  },
  maxLength: {
    value: 30,
    message: "Username must be at most 30 characters",
  },
  pattern: {
    value: /^[a-zA-Z0-9_.]*$/, // after 9, insert the allowable characters,
    message:
      "You can only use letters, numbers, dots and underscores for Username",
  },
};

export const phoneNumberRules = {
  required: "Phone number is required",
  minLength: {
    value: 9,
    message: "Phone must be at least 9 digits",
  },
  maxLength: {
    value: 12,
    message: "Phone must be at most 12 digits",
  },
  pattern: {
    value: /^[0-9]*$/,
    message: "Only digits are allowed",
  },
};

export const emailRules = {
  required: "Email is required",
  pattern: {
    value: /\S+@\S+\.\S+/,
    message: "Your email does not look right",
  },
};

export const firstLastNameRules = {
  required: "Item required",
  pattern: {
    value: /^[a-zA-Z]{1}[a-zA-Z ']{0,}$/, // forced to at least include one letter vs space at the beginning, trailing white spaces will be handled
    message: "Letters / spaces only",
  },
  minLength: {
    value: 2,
    message: "Min 2 characters",
  },
  maxLength: {
    value: 100,
    message: "Max 100 characters",
  },
};

export const fullNameRules = {
  required: "Name is required",
  pattern: {
    value: /^[a-zA-Z]{1}[a-zA-Z ']{0,}$/, // forced to at least include one letter vs space at the beginning, trailing white spaces will be handled
    message: "Letters / spaces only",
  },
  minLength: {
    value: 2,
    message: "Min 2 characters",
  },
  maxLength: {
    value: 100,
    message: "Max 100 characters",
  },
};

export const contactDescriptionRules = {
  required: "Description is required",
  // pattern: {
  //   value: /^[a-zA-Z]{1}[a-zA-Z ']{0,}$/, // forced to at least include one letter vs space at the beginning, trailing white spaces will be handled
  //   message: "Letters / spaces only",
  // },
  minLength: {
    value: 5,
    message: "Min 5 characters",
  },
  maxLength: {
    value: 200,
    message: "Max 200 characters",
  },
};

export const passwordRules = {
  required: "Password is required",
  minLength: {
    value: 6,
    message: "Password must be at least 6 characters",
  },
  maxLength: {
    value: 40,
    message: "Password must be at most 40 characters",
  },
};
