import { useState, useRef, useEffect } from "react";
import {
  Modal,
  StyleSheet,
  Text,
  Pressable,
  View,
  ActivityIndicator,
  Platform,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useForm, Controller } from "react-hook-form";
import * as Linking from "expo-linking";
import ToastDefault from "../Toast/ToastDefault";

import { Motion } from "@legendapp/motion/";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import Timer from "../FormHelper/Timer";
import {
  handleCheckUsername,
  handleRequestOtp,
  handleVerifyOtp,
} from "./authFunctions";
import {
  emailRules,
  firstLastNameRules,
  passwordRules,
  phoneNumberRules,
  usernameRules,
} from "../FormHelper/formRules";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { supabase } from "../../utils/supabase";
import AlertModal from "../AlertModal";
import {
  ButtonPrimaryCustom,
  ButtonTextCustomDarkOrange,
  ButtonTextCustomOrange,
} from "../Buttons";
import { useModalStore } from "../../utils/store";
import { modalAnimationType } from "../../utils/deviceInfo";
import { Picker } from "@react-native-picker/picker";
import { useNavigate } from "react-router-dom"; // for web only

export default function EmailSignUpModal() {
  const navigate = Platform.OS === "web" ? useNavigate() : null;

  const [selectedAgeRange, setSelectedAgeRange] = useState();
  const [genderSwitch, setGenderSwitch] = useState(0);
  const [selectedGender, setSelectedGender] = useState("male");

  const [modalVisible, setModalVisible] = useState(false);
  const [modalAlertVisible, setModalAlertVisible] = useState(false);

  const [loadingEmailSU, setLoadingEmailSU] = useState(false);
  const [loadingCheckUsername, setLoadingCheckUsername] = useState(false);
  const [loadingRequestOtp, setLoadingRequestOtp] = useState(false);
  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false);

  const [usernameValidated, setUsernameValidated] = useState(false);
  const [usernameTextRed, setUsernameTextRed] = useState(false);
  const [phoneValidated, setPhoneValidated] = useState(false);

  // ZUSTAND
  const setModalShowing = useModalStore((state) => state.setModalShowing);
  const setModalTwoShowing = useModalStore((state) => state.setModalTwoShowing);

  // helper for Alert Modal
  const handleAlertModalExit = () => {
    setPhoneValidated(false);
    setUsernameValidated(false);
    reset(); // reset the form
    setModalAlertVisible(false);
    setModalTwoShowing(false);
    setModalVisible(false);
    setModalShowing(false);
  };

  const [displayPinPad, setDisplayPinPad] = useState(false);
  const [mvdRequestId, setMvdRequestId] = useState<string>("");
  const [otpValue, setOtpValue] = useState("");
  const [codeFieldProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value: otpValue,
    setValue: setOtpValue,
  });

  const CELL_COUNT = 4; // our OTP is 4-digit long
  const TIMER_SECONDS = 180 + 10; // 3 mins plus 10 seconds buffer

  const codeFieldRef = useBlurOnFulfill({
    value: otpValue,
    cellCount: CELL_COUNT,
  });
  const toastRef: any = useRef();

  const [isShowingTimer, setIsShowingTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(TIMER_SECONDS);

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmedPassword: "",
    },
  });

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    otpValue.length === CELL_COUNT &&
      handleVerifyOtp(
        otpValue,
        mvdRequestId,
        getValues,
        toastRef,
        setLoadingVerifyOtp,
        setPhoneValidated,
        setMvdRequestId,
        setOtpValue,
        setDisplayPinPad
      );
  }, [otpValue]);

  // data from react-hook-form
  // data =  {email: "" , password: "", ... }
  async function signUpWithEmail({
    username,
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    confirmedPassword,
  }: any) {
    setLoadingEmailSU(true);
    // should never happen because react-hook-form check this, but just in case
    if (password !== confirmedPassword) {
      toastRef.current.show("Password does not match!");
      return;
    }
    const { user, error } = await supabase.auth.signUp(
      {
        email,
        password,
      },
      {
        //redirectTo: "https://dealsifu-web.vercel.app/", // "http://localhost:19006", // after redirect no longer has control of current page context
        data: {
          email,
          full_name: firstName + " " + lastName,
          first_name: firstName,
          last_name: lastName,
          username,
          phone_num: phoneNumber,
        },
      }
    );
    if (error) alert(error.message);

    if (user) {
      // console.log("my user", user);
      // alert("User registration is successful. Please verify your email.");
      setModalAlertVisible(true);
      setModalTwoShowing(true);
    }
    setLoadingEmailSU(false);
  }

  return (
    <>
      <Modal
        animationType={modalAnimationType}
        transparent={true}
        statusBarTranslucent={true}
        visible={modalVisible}
        onRequestClose={() => {
          // this is for Android only
          // Alert.alert("Modal has been closed.");
          setModalVisible(false);
          setModalShowing(false);
        }}
      >
        <SafeAreaProvider>
          <SafeAreaView className="flex-1 bg-black/50">
            <TouchableWithoutFeedback
              onPress={Platform.OS !== "web" ? Keyboard.dismiss : () => {}}
            >
              <KeyboardAvoidingView
                behavior={"padding"}
                className="items-center justify-center flex-1"
              >
                <View
                  style={styles.modalView}
                  className="flex-col items-center justify-center w-full max-w-xs p-8 bg-gray-100 rounded"
                >
                  <Pressable
                    className="absolute top-3 right-3"
                    onPress={() => {
                      setModalVisible(false);
                      setModalShowing(false);
                    }}
                  >
                    <Ionicons name="md-close-sharp" size={30} color="black" />
                  </Pressable>

                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    indicatorStyle="black"
                    className="w-full"
                  >
                    <Text className="mb-1 text-orange-500">Username</Text>
                    <Controller
                      control={control}
                      rules={usernameRules}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View className="flex-row items-center w-full p-1.5 mb-5 bg-gray-200 rounded">
                          <View className="basis-3/4">
                            <TextInput
                              className={
                                usernameTextRed
                                  ? "text-red-500 bg-transparent caret-black"
                                  : "text-black bg-transparent caret-black"
                              }
                              style={Platform.select(rnw_outline_none)}
                              onBlur={onBlur}
                              onChangeText={(e) => {
                                onChange(e);
                                if (usernameValidated === true) {
                                  setUsernameValidated(false);
                                }
                                if (usernameTextRed === true) {
                                  setUsernameTextRed(false);
                                }
                              }}
                              value={value}
                              keyboardType={"default"}
                              placeholder={"Choose a username"}
                              placeholderTextColor="gray"
                            />
                          </View>
                          <View className="px-1 py-1 bg-gray-300 rounded basis-1/4">
                            {loadingCheckUsername ? (
                              <View className="flex items-center justify-center py-2">
                                <ActivityIndicator
                                  className="absolute"
                                  color="black"
                                />
                              </View>
                            ) : usernameValidated ? (
                              <Text className="text-xs text-center">OK ✅</Text>
                            ) : (
                              <TouchableOpacity
                                onPress={() => {
                                  handleCheckUsername(
                                    getValues,
                                    trigger,
                                    toastRef,
                                    setLoadingCheckUsername,
                                    setUsernameTextRed,
                                    setUsernameValidated
                                  );
                                }}
                              >
                                <Text className="text-xs text-center">
                                  check
                                </Text>
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>
                      )}
                      name="username"
                    />
                    {errors.username && (
                      <Text className="mb-5 -mt-4 text-red-700">
                        {errors.username.message}
                      </Text>
                    )}

                    <View className="flex-row">
                      <View className="basis-[47%]">
                        <Text className="mb-1 text-orange-500">First Name</Text>
                        <Controller
                          control={control}
                          rules={firstLastNameRules}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput
                              className="p-2 mb-5 bg-gray-200 rounded caret-black"
                              style={Platform.select(rnw_outline_none)}
                              onBlur={onBlur}
                              onChangeText={onChange}
                              value={value}
                              keyboardType={"default"}
                              placeholder={"First name"}
                              placeholderTextColor="gray"
                            />
                          )}
                          name="firstName"
                        />
                        {errors.firstName && (
                          <Text className="mb-5 -mt-4 text-red-700">
                            {errors.firstName.message}
                          </Text>
                        )}
                      </View>

                      <View className="basis-[6%]"></View>

                      <View className="basis-[47%]">
                        <Text className="mb-1 text-orange-500">Last Name</Text>
                        <Controller
                          control={control}
                          rules={firstLastNameRules}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput
                              className="p-2 mb-5 bg-gray-200 rounded caret-black"
                              style={Platform.select(rnw_outline_none)}
                              onBlur={onBlur}
                              onChangeText={onChange}
                              value={value}
                              keyboardType={"default"}
                              placeholder={"Last name"}
                              placeholderTextColor="gray"
                            />
                          )}
                          name="lastName"
                        />
                        {errors.lastName && (
                          <Text className="mb-5 -mt-4 text-red-700">
                            {errors.lastName.message}
                          </Text>
                        )}
                      </View>
                    </View>

                    <Text className="mb-1 text-orange-500">Email</Text>
                    <Controller
                      control={control}
                      rules={emailRules}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          className="p-2 mb-5 bg-gray-200 rounded caret-black"
                          style={Platform.select(rnw_outline_none)}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          keyboardType={"default"}
                          placeholder={"Enter email"}
                          placeholderTextColor="gray"
                        />
                      )}
                      name="email"
                    />
                    {errors.email && (
                      <Text className="mb-5 -mt-4 text-red-700">
                        {errors.email.message}
                      </Text>
                    )}

                    <Text className="mb-1 text-orange-500">Phone Number</Text>
                    <Controller
                      control={control}
                      rules={phoneNumberRules}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View className="flex-row items-center w-full p-1.5 mb-5 bg-gray-200 rounded">
                          <View className="basis-3/4">
                            <TextInput
                              className={
                                phoneValidated
                                  ? "text-gray-500 bg-transparent"
                                  : "text-black bg-transparent caret-black"
                              }
                              style={Platform.select(rnw_outline_none)}
                              onBlur={onBlur}
                              onChangeText={(e) => {
                                onChange(e.replace(/[^0-9]/g, "")); // we want digits only
                              }}
                              value={value}
                              keyboardType={"numeric"}
                              placeholder={"Malaysian mobile only"}
                              placeholderTextColor="gray"
                              editable={!phoneValidated}
                              selectTextOnFocus={!phoneValidated}
                            />
                          </View>
                          <View className="px-1 py-1 bg-gray-300 rounded basis-1/4">
                            {loadingRequestOtp ? (
                              <View className="flex items-center justify-center py-2">
                                <ActivityIndicator
                                  className="absolute"
                                  color="black"
                                />
                              </View>
                            ) : phoneValidated ? (
                              <Text className="text-xs text-center">OK ✅</Text>
                            ) : !isShowingTimer ? (
                              <TouchableOpacity
                                onPress={() => {
                                  handleRequestOtp(
                                    getValues,
                                    trigger,
                                    toastRef,
                                    setLoadingRequestOtp,
                                    setPhoneValidated,
                                    setMvdRequestId,
                                    setDisplayPinPad,
                                    setIsShowingTimer
                                  );
                                }}
                              >
                                <Text className="text-xs text-center">
                                  verify
                                </Text>
                              </TouchableOpacity>
                            ) : (
                              <TouchableOpacity onPress={() => {}} disabled>
                                <Timer
                                  setIsShowingTimer={setIsShowingTimer}
                                  timeLeft={timeLeft}
                                  setTimeLeft={setTimeLeft}
                                />
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>
                      )}
                      name="phoneNumber"
                    />
                    {errors.phoneNumber && (
                      <Text className="mb-5 -mt-4 text-red-700">
                        {errors.phoneNumber.message}
                      </Text>
                    )}

                    {displayPinPad ? (
                      <Motion.View
                        key="A"
                        className={Platform.OS === "android" ? "mb-1" : "mb-5"}
                        initial={{ y: 0 }}
                        animate={{ y: 5 }}
                        transition={{
                          type: "spring",
                          damping: 20,
                          stiffness: 1000,
                          mass: 2,
                        }}
                      >
                        <Motion.View className="flex-row items-center">
                          <Motion.Text className="mb-1 mr-2 -mt-2 text-orange-500">
                            SMS Verification Code
                          </Motion.Text>
                          {loadingVerifyOtp && (
                            <ActivityIndicator className="-mt-4" color="gray" />
                          )}
                        </Motion.View>

                        <CodeField
                          ref={codeFieldRef}
                          {...codeFieldProps}
                          // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
                          value={otpValue}
                          onChangeText={setOtpValue}
                          cellCount={CELL_COUNT}
                          keyboardType="numeric"
                          textContentType="oneTimeCode"
                          renderCell={({ index, symbol, isFocused }) => (
                            <Text
                              key={index}
                              style={[
                                styles.cell,
                                isFocused && styles.focusCell,
                              ]}
                              onLayout={getCellOnLayoutHandler(index)}
                            >
                              {symbol || (isFocused ? <Cursor /> : null)}
                            </Text>
                          )}
                        />
                        <Text className="mt-2 text-xs italic text-justify text-gray-500">
                          TUNETALK users, please contact{" "}
                          {Platform.OS === "web" ? (
                            <Pressable
                              className="font-bold underline"
                              onPress={() => {
                                window.open(
                                  "https://dealsifu.com/contact-us",
                                  "_blank"
                                );
                              }}
                            >
                              <Text>support</Text>
                            </Pressable>
                          ) : (
                            <Text>support.</Text>
                          )}{" "}
                          first. SMS will come from MOVIDER. The countdown is
                          just an estimation.
                        </Text>
                      </Motion.View>
                    ) : null}

                    <Text className="mb-2 text-orange-500">Age Range</Text>
                    <View className="w-24 mb-3">
                      <Picker
                        style={Platform.OS === "web" ? styles.picker : null}
                        selectedValue={selectedAgeRange}
                        onValueChange={(itemValue, itemIndex) => {
                          setSelectedAgeRange(itemValue);
                          console.log(itemValue);
                        }}
                      >
                        <Picker.Item label="1" value="1" />
                        <Picker.Item label="2" value="2" />
                        <Picker.Item label="3" value="3" />
                        <Picker.Item label="4" value="4" />
                        <Picker.Item label="5" value="5" />
                        <Picker.Item label="6" value="6" />
                        <Picker.Item label="7" value="7" />
                        <Picker.Item label="8" value="8" />
                        <Picker.Item label="9" value="9" />
                        <Picker.Item label="10" value="10" />
                        <Picker.Item label="11" value="11" />
                        <Picker.Item label="12" value="12" />
                      </Picker>
                    </View>

                    <Text className="mb-2 text-orange-500">Gender</Text>
                    <View className="flex-col mb-2">
                      <Motion.View
                        className="absolute left-0 top-[5.5px]"
                        animate={{
                          x: genderSwitch * 120 + 15,
                        }}
                        transition={{
                          type: "spring",
                          damping: 20,
                          stiffness: 300,
                        }}
                      >
                        <View className="w-[85px] h-[30px] bg-orange-500 rounded"></View>
                      </Motion.View>
                      <View className="flex-row justify-around mb-3 border-2 border-gray-200 rounded">
                        <Pressable
                          className="px-4 py-2.5 rounded"
                          onPress={() => {
                            setSelectedGender("male");
                            setGenderSwitch(0);
                          }}
                        >
                          <Text
                            className={
                              selectedGender === "male"
                                ? "text-white"
                                : "text-gray-600"
                            }
                          >
                            Male
                          </Text>
                        </Pressable>
                        <Pressable
                          className="px-3 py-2.5 rounded"
                          onPress={() => {
                            setSelectedGender("female");
                            setGenderSwitch(1);
                          }}
                        >
                          <Text
                            className={
                              selectedGender === "female"
                                ? "text-white"
                                : "text-gray-600"
                            }
                          >
                            Female
                          </Text>
                        </Pressable>
                      </View>
                    </View>

                    <Text className="mb-1 text-orange-500">Password</Text>
                    <Controller
                      control={control}
                      rules={passwordRules}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          className="p-2 mb-5 bg-gray-200 rounded caret-black"
                          style={Platform.select(rnw_outline_none)}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          secureTextEntry={true}
                          placeholder={"Create password"}
                          placeholderTextColor="gray"
                        />
                      )}
                      name="password"
                    />
                    {errors.password && (
                      <Text className="mb-5 -mt-4 text-red-700">
                        {errors.password.message}
                      </Text>
                    )}

                    <Text className="mb-1 text-orange-500">
                      Confirm Password
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        validate: (value) =>
                          value === password.current ||
                          "The passwords do not match",
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          className="p-2 mb-5 bg-gray-200 rounded caret-black"
                          style={Platform.select(rnw_outline_none)}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          secureTextEntry={true}
                          placeholder={"Confirm password"}
                          placeholderTextColor="gray"
                        />
                      )}
                      name="confirmedPassword"
                    />
                    {errors.confirmedPassword && (
                      <Text className="mb-5 -mt-4 text-red-700">
                        {errors.confirmedPassword.message}
                      </Text>
                    )}

                    <ButtonPrimaryCustom
                      onPress={handleSubmit(signUpWithEmail)}
                      isDisabled={
                        loadingEmailSU || !usernameValidated || !phoneValidated
                      }
                      loading={loadingEmailSU}
                      buttonText="Sign up with Email"
                      customTailwind={
                        usernameValidated && phoneValidated
                          ? "w-full py-3 bg-orange-600 rounded hover:bg-orange-700 active:bg-orange-800"
                          : "w-full py-3 bg-gray-400 rounded"
                      }
                    />
                    <ButtonTextCustomDarkOrange
                      twPressable="mt-4"
                      twText="text-center"
                      onPress={() => {
                        const url = "https://forum.dealsifu.com/privacy";
                        if (Platform.OS == "web") {
                          window.open(url, "_blank");
                        } else {
                          Linking.openURL(url);
                        }
                      }}
                      buttonText="We care about your data privacy"
                    />
                  </ScrollView>
                </View>
              </KeyboardAvoidingView>
            </TouchableWithoutFeedback>
          </SafeAreaView>
        </SafeAreaProvider>
        <AlertModal
          modalAlertVisible={modalAlertVisible}
          setModalAlertVisible={setModalAlertVisible}
          handleAlertModalExit={handleAlertModalExit}
          textPrimary="User registration is successful."
          textSecondary="Please check your email for verification."
        />
        <ToastDefault ref={toastRef} />
      </Modal>
      <ButtonTextCustomOrange
        twPressable="absolute right-0"
        onPress={() => {
          if (Platform.OS === "web") {
            if (navigate) {
              navigate("/email-signup"); // this also result in theme following system theme instead of the dark switch button
            }
          } else {
            // TODO EDIT MODAL FOR MOBILE
            setModalVisible(true);
            setModalShowing(true);
          }
        }}
        buttonText="Sign up (email)"
      />
    </>
  );
}

const styles = StyleSheet.create({
  modalView: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  cell: {
    width: 40,
    height: 40,
    lineHeight: 32, // initially 38
    fontSize: 24,
    fontWeight: "300",
    textAlign: "center",
    borderWidth: 2,
    borderColor: "#9ca3af", // bg-gray-400
    backgroundColor: "#e5e7eb", // bg-gray-200
  },
  focusCell: {
    borderColor: "#f97316", // bg-orange-500
  },
  picker: {
    borderRadius: 4,
    borderColor: "#e5e7eb",
    paddingHorizontal: 3, // this has no effect in Safari browser, will go with default for that
    height: 35, // because paddingVertical does not work in Safari browser
    color: "black",
    fontSize: 14,
    backgroundColor: "#e5e7eb", // bg-gray-200
  },
});

// workaround, for react-native-web by default, it has border outline around TextInput when focused
// https://stackoverflow.com/questions/53639013/typescript-and-react-native-are-the-type-definitions-for-rn-styles-wrong
const rnw_outline_none: any = {
  web: {
    outlineStyle: "none",
  },
};
