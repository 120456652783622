import {
  Pressable,
  View,
  ActivityIndicator,
  Text,
  GestureResponderEvent,
} from "react-native";
import LogoGoogleColored from "../Svg/LogoGoogleColored";
import { Ionicons } from "@expo/vector-icons";

interface PropsButton {
  onPress: (event: GestureResponderEvent) => void;
  isDisabled?: boolean;
  loading?: boolean;
  buttonText: string;
  buttonTextSecondary?: string;
  widthTailwind?: string;
  marginTailwind?: string;
}

interface PropsButtonCustom {
  onPress: (event: GestureResponderEvent) => void;
  isDisabled?: boolean;
  loading?: boolean;
  buttonText: string;
  customTailwind: string;
}

export function ButtonPrimary({
  onPress,
  isDisabled = false,
  loading = false,
  buttonText,
  buttonTextSecondary = "",
  widthTailwind = "w-full",
  marginTailwind = "m-0",
}: PropsButton) {
  const pressableClassName = `${widthTailwind} py-2.5 ${marginTailwind} bg-orange-600 rounded hover:bg-orange-700 active:bg-orange-800`;
  const textClassName = isDisabled
    ? "font-bold text-center text-[#444]"
    : "text-white font-bold text-center";
  return (
    <Pressable
      className={pressableClassName}
      onPress={onPress}
      disabled={isDisabled}
    >
      {loading ? (
        <View className="flex items-center justify-center py-2">
          {/*  absolute positioning result in zero y-padding in button (because we want to remove default ActivityIndicator margin/padding of 1), hence we add "some" back in View */}
          <ActivityIndicator className="absolute" color="white" />
        </View>
      ) : (
        <View className="flex-row justify-center">
          <Text className={textClassName}>{buttonText}</Text>
          <Text className={textClassName}>{buttonTextSecondary}</Text>
        </View>
      )}
    </Pressable>
  );
}

export function ButtonPrimaryGreen({
  onPress,
  isDisabled = false,
  loading = false,
  buttonText,
  buttonTextSecondary = "",
  widthTailwind = "w-full",
  marginTailwind = "m-0",
}: PropsButton) {
  const pressableClassName = `${widthTailwind} py-2.5 ${marginTailwind} bg-green-600 rounded hover:bg-green-700 active:bg-green-800`;
  const textClassName = isDisabled
    ? "font-bold text-center text-[#444]"
    : "text-white font-bold text-center";
  return (
    <Pressable
      className={pressableClassName}
      onPress={onPress}
      disabled={isDisabled}
    >
      {loading ? (
        <View className="flex items-center justify-center py-2">
          {/*  absolute positioning result in zero y-padding in button (because we want to remove default ActivityIndicator margin/padding of 1), hence we add "some" back in View */}
          <ActivityIndicator className="absolute" color="white" />
        </View>
      ) : (
        <View className="flex-row justify-center">
          <Text className={textClassName}>{buttonText}</Text>
          <Text className={textClassName}>{buttonTextSecondary}</Text>
        </View>
      )}
    </Pressable>
  );
}

export function ButtonPrimaryCustom({
  onPress,
  isDisabled = false,
  loading = false,
  buttonText,
  customTailwind,
}: PropsButtonCustom) {
  const textClassName = isDisabled
    ? "font-bold text-center text-[#444]"
    : "font-bold text-center text-white";
  return (
    <Pressable
      className={customTailwind}
      onPress={onPress}
      disabled={isDisabled}
    >
      {loading ? (
        <View className="flex items-center justify-center py-2">
          {/*  absolute positioning result in zero y-padding in button (because we want to remove default ActivityIndicator margin/padding of 1), hence we add "some" back in View */}
          <ActivityIndicator className="absolute" color="white" />
        </View>
      ) : (
        <Text className={textClassName}>{buttonText}</Text>
      )}
    </Pressable>
  );
}

export function ButtonPrimaryGoogle({
  onPress,
  isDisabled = false,
  loading,
  buttonText,
  widthTailwind = "w-full",
  marginTailwind = "m-0",
}: PropsButton) {
  const pressableClassName = `${widthTailwind} py-2.5 ${marginTailwind} bg-white rounded hover:bg-gray-200 active:bg-gray-300`;
  return (
    <Pressable
      className={pressableClassName}
      onPress={onPress}
      disabled={isDisabled}
    >
      {loading ? (
        <View className="flex items-center justify-center py-2">
          {/*  absolute positioning result in zero y-padding in button (because we want to remove default ActivityIndicator margin/padding of 1), hence we add "some" back in View */}
          <ActivityIndicator className="absolute" color="black" />
        </View>
      ) : (
        <View className="flex-row items-center justify-center">
          <View className="w-4 h-4">
            <LogoGoogleColored />
          </View>
          <Text className="ml-4 font-bold text-center text-[#444] text-sm">
            {buttonText}
          </Text>
        </View>
      )}
    </Pressable>
  );
}

export function ButtonPrimaryGoogleCustom({
  onPress,
  isDisabled = false,
  loading,
  buttonText,
  customTailwind,
}: PropsButtonCustom) {
  return (
    <Pressable
      className={customTailwind}
      onPress={onPress}
      disabled={isDisabled}
    >
      {loading ? (
        <View className="flex items-center justify-center py-2">
          {/*  absolute positioning result in zero y-padding in button (because we want to remove default ActivityIndicator margin/padding of 1), hence we add "some" back in View */}
          <ActivityIndicator className="absolute" color="black" />
        </View>
      ) : (
        <View className="flex-row items-center justify-center">
          {isDisabled ? (
            <Ionicons name="logo-google" size={15} color="#444" />
          ) : (
            <View className="w-4 h-4">
              <LogoGoogleColored />
            </View>
          )}

          <Text className="ml-4 font-bold text-center text-[#444]">
            {buttonText}
          </Text>
        </View>
      )}
    </Pressable>
  );
}

interface PropsButtonTextCustom {
  onPress: (event: GestureResponderEvent) => void;
  isDisabled?: boolean;
  buttonText: string;
  twPressable?: string;
  twText?: string;
}

export function ButtonTextCustomOrange({
  onPress,
  isDisabled = false,
  buttonText,
  twPressable = "",
  twText = "",
}: PropsButtonTextCustom) {
  const pressableClassName = `${twPressable}`;
  const textClassNamePressed = `${twText} text-sm text-orange-700`;
  const textClassNameUnpressed = `${twText} text-sm text-orange-500`;
  return (
    <Pressable
      className={pressableClassName}
      onPress={onPress}
      disabled={isDisabled}
    >
      {({ pressed }) => (
        <Text
          className={pressed ? textClassNamePressed : textClassNameUnpressed}
        >
          {buttonText}
        </Text>
      )}
    </Pressable>
  );
}

interface PropsButtonTextCustomIcon {
  onPress: (event: GestureResponderEvent) => void;
  isDisabled?: boolean;
  buttonText: string;
  twPressable?: string;
  twText?: string;
  iconName: any;
  iconSize?: number;
}

export function ButtonTextCustomOrangeIonicons({
  onPress,
  isDisabled = false,
  buttonText,
  twPressable = "",
  twText = "",
  iconName,
  iconSize = 24,
}: PropsButtonTextCustomIcon) {
  const pressableClassName = `${twPressable}`;
  const textClassNamePressed = `${twText} text-sm text-orange-700`;
  const textClassNameUnpressed = `${twText} text-sm text-orange-500`;
  return (
    <Pressable
      className={pressableClassName}
      onPress={onPress}
      disabled={isDisabled}
    >
      {({ pressed }) => (
        <Text
          className={pressed ? textClassNamePressed : textClassNameUnpressed}
        >
          {buttonText}
          <Ionicons name={iconName} size={iconSize} />
        </Text>
      )}
    </Pressable>
  );
}

export function ButtonTextCustomDarkOrange({
  onPress,
  isDisabled = false,
  buttonText,
  twPressable = "",
  twText = "",
}: PropsButtonTextCustom) {
  const pressableClassName = `${twPressable}`;
  const textClassNamePressed = `${twText} text-sm text-orange-900`;
  const textClassNameUnpressed = `${twText} text-sm text-orange-700`;
  return (
    <Pressable
      className={pressableClassName}
      onPress={onPress}
      disabled={isDisabled}
    >
      {({ pressed }) => (
        <Text
          className={pressed ? textClassNamePressed : textClassNameUnpressed}
        >
          {buttonText}
        </Text>
      )}
    </Pressable>
  );
}
