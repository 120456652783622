import Toast from "react-native-toast-notifications";
import { View, Text, Pressable, Platform } from "react-native";
import React, { forwardRef, useState, useEffect } from "react";

const ToastDefault = forwardRef(
  (
    {
      isScrollable = false,
      isRoot = false,
    }: { isScrollable?: boolean; isRoot?: boolean },
    ref: any
  ) => {
    // for web only
    const [scrollPosition, setScrollPosition] = useState(0); // default to 0
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      // console.log("myposition:", position);
    };
    useEffect(() => {
      if (Platform.OS === "web" && isScrollable) {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    }, []);
    return (
      <Toast
        ref={ref}
        offsetTop={scrollPosition + 10}
        placement="top"
        onPress={() => {
          isRoot ? toast.hideAll() : ref.current.hideAll();
        }}
        swipeEnabled={true}
        renderType={{
          custom_type: (toastItem) => (
            <View
              style={{
                padding: 15,
                backgroundColor: "red",
                flex: 1,
                flexDirection: "row",
              }}
            >
              <Text>{toastItem.message}</Text>
              <Text>|</Text>
              <Pressable
                onPress={() => {
                  isRoot
                    ? toast.hide(toastItem.id)
                    : ref.current.hide(toastItem.id);
                }}
              >
                <Text>X</Text>
              </Pressable>
            </View>
          ),
        }}
      />
    );
  }
);

export default ToastDefault;
