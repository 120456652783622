import { supabase } from "../../utils/supabase";
import { startAsync, makeRedirectUri } from "expo-auth-session";
import { ApiError, User } from "@supabase/supabase-js";
import * as WebBrowser from "expo-web-browser";
import * as Linking from "expo-linking";
import { Platform } from "react-native";

// if openAuthSessionAsync is invoked on https://localhost:19006, then maybeCompleteAuthSession must be invoked on a page hosted from the origin https://localhost:19006
// we invoked openAuthSessionAsync in Google Auth for web
WebBrowser.maybeCompleteAuthSession();

async function updateProfile({
  username,
  phone_num,
  first_name,
  last_name,
  email,
  full_name,
  age_range,
  gender,
}: {
  username: string | null; // null for first timer choosing wrong sign in button
  phone_num: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  full_name: string;
  age_range: string | null;
  gender: string | null;
}) {
  try {
    const user = supabase.auth.user();
    if (!user) throw new Error("No user on the session!");

    const updates = {
      id: user.id,
      username,
      phone_num,
      first_name,
      last_name,
      email,
      full_name,
      age_range,
      gender,
      updated_at: new Date(),
    };

    const { error } = await supabase
      .from("profiles")
      .upsert(updates, { returning: "minimal" });

    if (error) {
      throw error;
    }
  } catch (error) {
    alert((error as ApiError).message);
  }
}

async function updateProfileAvatar({ avatar_url }: { avatar_url: string }) {
  try {
    const user = supabase.auth.user();
    if (!user) throw new Error("No user on the session!");

    const updates = {
      id: user.id,
      avatar_url,
      updated_at: new Date(),
    };

    const { error } = await supabase
      .from("profiles")
      .upsert(updates, { returning: "minimal" });

    if (error) {
      throw error;
    }
  } catch (error) {
    alert((error as ApiError).message);
  }
}

// check value of username for specific user exist or not (yet)
// for new registration this will return TRUE, meaning the username does not exist yet
async function checkUsernameDoesNotExist(): Promise<boolean> {
  try {
    const user = supabase.auth.user();
    if (!user) throw new Error("No user on the session!");

    let { data, error, status } = await supabase
      .from("profiles")
      .select("username")
      .eq("id", user.id)
      .single();

    // console.log("checkUsername", data);

    // 406 : missing or invalid credentials
    if (error && status !== 406) {
      throw error;
    }

    if (data === null || data.username === null) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    alert((error as ApiError).message);
    return false;
  }
}

/// helper function for if Google Auth is successful
async function handleSuccessfulGoogleAuth(
  user: User,
  newUsername: string | null,
  newPhone: string | null,
  selectedAgeRange: string | null,
  selectedGender: string | null
) {
  const usernameDoesNotExist = await checkUsernameDoesNotExist();

  // click sign in instead of sign up (for first timer)
  if (usernameDoesNotExist && newUsername === null) {
    let newFirstName = null;
    let newLastName = null;
    let fullName = null;
    let email = null;

    if (user.user_metadata.full_name) {
      // for now we just spit the first portion of name to first_name, the rest to last_name
      // https://bobbyhadz.com/blog/javascript-split-string-only-on-first-instance-of-character
      fullName = user.user_metadata.full_name;
      const [firstSplit, ...rest] = fullName.split(" ");
      newFirstName = firstSplit;
      newLastName = rest.join(" "); // join with space in between
    }

    if (user.user_metadata.email) {
      email = user.user_metadata.email;
    }

    // we continue to make the username null until user properly register
    await updateProfile({
      username: null,
      phone_num: newPhone,
      first_name: newFirstName,
      last_name: newLastName,
      email: email,
      full_name: fullName,
      age_range: selectedAgeRange, // this is null
      gender: selectedGender, // this is null
    });

    // logout user here
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast.show(error.message, { type: "warning" });
    }
    toast.show(
      'New user, please click "Sign up with Google" and register first.',
      {
        type: "warning",
      }
    );
    return;
  }

  // FOR SIGN UP
  // username does not exist means FIRST TIME REGISTER (which means we can use user_metadata)
  if (usernameDoesNotExist) {
    let newFirstName = null;
    let newLastName = null;
    let fullName = null;
    let email = null;

    if (user.user_metadata.full_name) {
      // for now we just spit the first portion of name to first_name, the rest to last_name
      // https://bobbyhadz.com/blog/javascript-split-string-only-on-first-instance-of-character
      fullName = user.user_metadata.full_name;
      const [firstSplit, ...rest] = fullName.split(" ");
      newFirstName = firstSplit;
      newLastName = rest.join(" "); // join with space in between
    }

    if (user.user_metadata.email) {
      email = user.user_metadata.email;
    }

    // update user here as well, incase of 406 rare error if update directly with postgres trigger for first-time user
    await updateProfile({
      username: newUsername,
      phone_num: newPhone,
      first_name: newFirstName,
      last_name: newLastName,
      email: email,
      full_name: fullName,
      age_range: selectedAgeRange,
      gender: selectedGender,
    });

    // We only use for ios and android because Google image does not set CORS when sending back the image via axios or fetch (which result in CORS error for website)
    // https://stackoverflow.com/questions/45975135/access-control-origin-header-error-using-axios
    // to overcome CORS issue, we upload via serverless function
    // metadata from Google Profile
    if (Platform.OS !== "web") {
      if (user.user_metadata.avatar_url) {
        const googleImageUrl = user.user_metadata.avatar_url;
        let newGoogleImageUrl = googleImageUrl.substring(
          0,
          googleImageUrl.lastIndexOf("=")
        ); // remove "=s96-c"
        newGoogleImageUrl += "=s200-c"; // add this to have an image which is 200x200
        const response = await fetch(newGoogleImageUrl);
        const imageBlob = await response.blob();

        if (imageBlob) {
          // Math.random() return value between 0.000000001... to 0.9999999999..., we want to remove "0.", we use slice(2)
          const fileName = `${Math.random().toString().slice(2)}_${new Date()
            .toJSON() // 2022-07-27T05:57:28.465Z
            .replace(/\.|:/g, "-")}`; // regex replace all occurence of period "\." OR ":" (period need to be \)

          const fileExt = imageBlob.type.split("/")[1]; // "image/jpeg"
          const filePath = `${fileName}.${fileExt}`; // include folder if any, here we only have bucket without folders inside, else it will be "foldername/filename"

          // google profile pic: png for avatar letter or jpeg for avatar images
          let { error: uploadError } = await supabase.storage
            .from("last")
            .upload(filePath, imageBlob);

          if (uploadError) {
            throw uploadError;
          }

          await updateProfileAvatar({ avatar_url: filePath });
        }
      }
    } else {
      // if web
      if (user.user_metadata.avatar_url) {
        const { error: error_invoke } = await supabase.functions.invoke(
          "fetch-google-avatar",
          {
            body: JSON.stringify({
              googleImageUrl: user.user_metadata.avatar_url,
            }),
          }
        );
        if (error_invoke) alert(error_invoke.message);
      }
    }
    //alert(`Welcome, ${user.user_metadata.full_name}!`);
  } else {
    // if ALREADY REGISTERED (i.e. FOR SIGN IN)
    //alert(`Welcome back, ${user.user_metadata.full_name}!`);
  }
}

/// helper const(s)
// apparently session and user here null if we use standard supabase method, because will resolve earlier
// hence we need help from expo's WebBrowser.openAuthSessionAsync and startAsync
// proxy: https://auth.expo.io/@username/dealsifu-frontend ; non-proxy: exp://192.168.68.51:19000
// non proxy: "http://localhost:19006"
// for production web apps, we should hard code the redirect URL as it cannot be inferred
// const redirectUri =
//   Platform.OS === "web"
//     ? "http://localhost:19006/" // http://localhost:19006/ // https://dealsifu-web.vercel.app/
//     : makeRedirectUri({ useProxy: false });
const redirectUri = makeRedirectUri({ useProxy: false });
const provider = "google";
const authUrl = `${process.env.RN_SUPABASE_URL}/auth/v1/authorize?provider=${provider}&redirect_to=${redirectUri}`;

/********************************************* SIGN IN / SIGN UP *********************************************/

export const signInUpWithGoogleWeb = async (
  setLoading: any,
  usernameFromForm: string | null,
  phoneFromForm: string | null,
  selectedAgeRange: string | null,
  selectedGender: string | null
) => {
  //setLoading(true);
  await WebBrowser.openAuthSessionAsync(authUrl, redirectUri)
    .then(async (result: any) => {
      // redirected (but result.url will come from pop-up auth browser)
      if (!result) return;
      if (result.url.includes("access_token")) {
        // this will happen in pop-up auth browser
        let parsedURL = Linking.parse(result.url.replace("#", "?")!); // because we want #access_token to become ?access_token so that we can query the params

        const { user, error } = await supabase.auth.signIn({
          refreshToken: parsedURL.queryParams?.refresh_token as string,
        });

        if (error) throw error;
        // console.log("google user", user);
        if (user) {
          handleSuccessfulGoogleAuth(
            user,
            usernameFromForm,
            phoneFromForm,
            selectedAgeRange,
            selectedGender
          );
        }
      }
      // setLoading(false); // we redirect, so this can no longer be called
    })
    .catch((error: any) => {
      // setLoading(false);
      if ((error.message = "Cannot read properties of undefined")) {
        toast.show("Pop-up window closed, please try again.");
        // console.log("You close the pop-up window.");
        // return;
      } else {
        alert(error.error_description || error.message);
      }
    });
};

export const signInUpWithGoogleMobile = async (
  setLoading: any,
  usernameFromForm: string | null,
  phoneFromForm: string | null,
  selectedAgeRange: string | null,
  selectedGender: string | null
) => {
  await startAsync({
    authUrl,
    returnUrl: redirectUri,
  })
    .then(async (response: any) => {
      if (!response) return;
      const { user, error } = await supabase.auth.signIn({
        refreshToken: response.params?.refresh_token,
      });

      if (error) throw error;
      // console.log("google user", user);
      if (user) {
        handleSuccessfulGoogleAuth(
          user,
          usernameFromForm,
          phoneFromForm,
          selectedAgeRange,
          selectedGender
        );
      }
    })
    .catch((error: any) => {
      // setLoading(false);
      alert(error.error_description || error.message);
    });
};
