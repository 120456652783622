import { useState, useRef, useEffect } from "react";
import {
  Modal,
  StyleSheet,
  Text,
  Pressable,
  View,
  ActivityIndicator,
  Platform,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  TouchableOpacity,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useForm, Controller } from "react-hook-form";
import { Motion } from "@legendapp/motion/";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import Timer from "../FormHelper/Timer";
import {
  handleCheckUsername,
  handleRequestOtp,
  handleVerifyOtp,
} from "./authFunctions";
import { phoneNumberRules, usernameRules } from "../FormHelper/formRules";
import { ButtonPrimaryGoogle, ButtonPrimaryGoogleCustom } from "../Buttons";
import {
  signInUpWithGoogleMobile,
  signInUpWithGoogleWeb,
} from "./authFunctionsGoogle";
import { useModalStore } from "../../utils/store";
import { modalAnimationType } from "../../utils/deviceInfo";
import PickerAgeRange from "../FormHelper/PickerAgeRange";
import PrivacyNotice from "../FormHelper/PrivacyNotice";
import GenderSwitchSelecter from "../FormHelper/GenderSwitchSelector";
import ToastDefault from "../Toast/ToastDefault";

export default function GoogleSignUpModal() {
  const [selectedAgeRange, setSelectedAgeRange] = useState<string>("");
  const [isGenderSelected, setIsGenderSelected] = useState<boolean>(false);
  const [genderSwitch, setGenderSwitch] = useState<number>(0);
  const [selectedGender, setSelectedGender] = useState<string>("");

  const [modalVisible, setModalVisible] = useState(false);

  const [loadingGoogleSU, setLoadingGoogleSU] = useState(false);
  const [loadingCheckUsername, setLoadingCheckUsername] = useState(false);
  const [loadingRequestOtp, setLoadingRequestOtp] = useState(false);
  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false);

  const [usernameValidated, setUsernameValidated] = useState(false);
  const [usernameTextRed, setUsernameTextRed] = useState(false);
  const [phoneValidated, setPhoneValidated] = useState(false);

  const [displayPinPad, setDisplayPinPad] = useState(false);
  const [mvdRequestId, setMvdRequestId] = useState<string>("");
  const [otpValue, setOtpValue] = useState("");
  const [codeFieldProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value: otpValue,
    setValue: setOtpValue,
  });

  const CELL_COUNT = 4; // our OTP is 4-digit long
  const TIMER_SECONDS = 180 + 10; // 3 mins plus 10 seconds buffer

  const codeFieldRef = useBlurOnFulfill({
    value: otpValue,
    cellCount: CELL_COUNT,
  });
  const toastRef: any = useRef();

  const [isShowingTimer, setIsShowingTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(TIMER_SECONDS);

  // ZUSTAND
  const setModalShowing = useModalStore((state) => state.setModalShowing);

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      phoneNumber: "",
    },
  });

  // if pass validation and user submit form
  async function handleGoogleSignUp({
    username,
    phoneNumber,
  }: {
    username: string;
    phoneNumber: string;
  }): Promise<void> {
    if (
      selectedAgeRange === "Please select an option..." ||
      selectedAgeRange === ""
    ) {
      toastRef.current.show("Age Range selection is required.", {
        type: "danger",
      });
      return;
    }

    if (!isGenderSelected) {
      toastRef.current.show("Gender is required.", { type: "danger" });
      return;
    }

    Platform.OS === "web"
      ? signInUpWithGoogleWeb(
          setLoadingGoogleSU,
          username,
          phoneNumber,
          selectedAgeRange,
          selectedGender
        )
      : signInUpWithGoogleMobile(
          setLoadingGoogleSU,
          username,
          phoneNumber,
          selectedAgeRange,
          selectedGender
        );
  }

  useEffect(() => {
    otpValue.length === CELL_COUNT &&
      handleVerifyOtp(
        otpValue,
        mvdRequestId,
        getValues,
        toastRef,
        setLoadingVerifyOtp,
        setPhoneValidated,
        setMvdRequestId,
        setOtpValue,
        setDisplayPinPad
      );
  }, [otpValue]);

  return (
    <>
      <Modal
        animationType={modalAnimationType}
        transparent={true}
        statusBarTranslucent={true}
        visible={modalVisible}
        onRequestClose={() => {
          // Alert.alert("Modal has been closed.");
          setModalVisible(false);
          setModalShowing(false);
        }}
      >
        <TouchableWithoutFeedback
          onPress={Platform.OS !== "web" ? Keyboard.dismiss : () => {}}
        >
          <View className="flex-1 bg-black/50">
            <View className="items-center justify-center flex-1">
              <View
                style={styles.modalView}
                className="flex-col items-center justify-center p-10 bg-gray-100 rounded w-80"
              >
                <Pressable
                  className="absolute top-3 right-3"
                  onPress={() => {
                    setModalVisible(false);
                    setModalShowing(false);
                  }}
                >
                  <Ionicons name="md-close-sharp" size={30} color="black" />
                </Pressable>

                <View className="w-full">
                  <Text className="mb-2 text-orange-500">Username</Text>
                  <Controller
                    control={control}
                    rules={usernameRules}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View className="flex-row items-center w-full p-2 mb-5 bg-gray-200 rounded">
                        <View className="basis-3/4">
                          <TextInput
                            className={
                              usernameTextRed
                                ? "text-red-500 bg-transparent caret-black"
                                : "text-black bg-transparent caret-black"
                            }
                            style={Platform.select(rnw_outline_none)}
                            onBlur={onBlur}
                            onChangeText={(e) => {
                              onChange(e);
                              if (usernameValidated === true) {
                                setUsernameValidated(false);
                              }
                              if (usernameTextRed === true) {
                                setUsernameTextRed(false);
                              }
                            }}
                            value={value}
                            keyboardType={"default"}
                            placeholder={"Choose a username"}
                            placeholderTextColor="gray"
                          />
                        </View>
                        <View className="px-1 py-1 bg-gray-300 rounded basis-1/4">
                          {loadingCheckUsername ? (
                            <View className="flex items-center justify-center py-2">
                              <ActivityIndicator
                                className="absolute"
                                color="black"
                              />
                            </View>
                          ) : usernameValidated ? (
                            <Text className="text-xs text-center">OK ✅</Text>
                          ) : (
                            <TouchableOpacity
                              onPress={() => {
                                handleCheckUsername(
                                  getValues,
                                  trigger,
                                  toastRef,
                                  setLoadingCheckUsername,
                                  setUsernameTextRed,
                                  setUsernameValidated
                                );
                              }}
                            >
                              <Text className="text-xs text-center">check</Text>
                            </TouchableOpacity>
                          )}
                        </View>
                      </View>
                    )}
                    name="username"
                  />
                  {errors.username && (
                    <Text className="mb-5 -mt-4 text-red-700">
                      {errors.username.message}
                    </Text>
                  )}

                  <Text className="mb-2 text-orange-500">Phone Number*</Text>

                  <Controller
                    control={control}
                    rules={phoneNumberRules}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View className="flex-row items-center w-full p-2 mb-5 bg-gray-200 rounded">
                        <View className="basis-3/4">
                          <TextInput
                            className={
                              phoneValidated
                                ? "text-gray-400 bg-transparent"
                                : "text-black bg-transparent caret-black"
                            }
                            style={Platform.select(rnw_outline_none)}
                            onBlur={onBlur}
                            onChangeText={(e) => {
                              onChange(e.replace(/[^0-9]/g, "")); // we want digits only
                            }}
                            value={value}
                            keyboardType={"numeric"}
                            placeholder={"Malaysian mobile only"}
                            placeholderTextColor="gray"
                            editable={!phoneValidated}
                            selectTextOnFocus={!phoneValidated}
                          />
                        </View>
                        <View className="px-1 py-1 bg-gray-300 rounded basis-1/4">
                          {loadingRequestOtp ? (
                            <View className="flex items-center justify-center py-2">
                              <ActivityIndicator
                                className="absolute"
                                color="black"
                              />
                            </View>
                          ) : phoneValidated ? (
                            <Text className="text-xs text-center">OK ✅</Text>
                          ) : !isShowingTimer ? (
                            <TouchableOpacity
                              onPress={() => {
                                handleRequestOtp(
                                  getValues,
                                  trigger,
                                  toastRef,
                                  setLoadingRequestOtp,
                                  setPhoneValidated,
                                  setMvdRequestId,
                                  setDisplayPinPad,
                                  setIsShowingTimer
                                );
                              }}
                            >
                              <Text className="text-xs text-center">
                                verify
                              </Text>
                            </TouchableOpacity>
                          ) : (
                            <TouchableOpacity onPress={() => {}} disabled>
                              <Timer
                                setIsShowingTimer={setIsShowingTimer}
                                timeLeft={timeLeft}
                                setTimeLeft={setTimeLeft}
                              />
                            </TouchableOpacity>
                          )}
                        </View>
                      </View>
                    )}
                    name="phoneNumber"
                  />
                  {errors.phoneNumber && (
                    <Text className="mb-5 -mt-4 text-red-700">
                      {errors.phoneNumber.message}
                    </Text>
                  )}

                  {displayPinPad ? (
                    <Motion.View
                      key="A"
                      className={Platform.OS === "android" ? "mb-1" : "mb-5"}
                      initial={{ y: 0 }}
                      animate={{ y: 5 }}
                      transition={{
                        type: "spring",
                        damping: 20,
                        stiffness: 1000,
                        mass: 2,
                      }}
                    >
                      <Motion.View className="flex-row items-center">
                        <Motion.Text className="mb-2 mr-2 -mt-2 text-orange-500">
                          SMS Verification Code
                        </Motion.Text>
                        {loadingVerifyOtp && (
                          <ActivityIndicator className="-mt-4" color="gray" />
                        )}
                      </Motion.View>

                      <CodeField
                        ref={codeFieldRef}
                        {...codeFieldProps}
                        // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
                        value={otpValue}
                        onChangeText={setOtpValue}
                        cellCount={CELL_COUNT}
                        keyboardType="numeric"
                        textContentType="oneTimeCode"
                        renderCell={({ index, symbol, isFocused }) => (
                          <Text
                            key={index}
                            style={[styles.cell, isFocused && styles.focusCell]}
                            onLayout={getCellOnLayoutHandler(index)}
                          >
                            {symbol || (isFocused ? <Cursor /> : null)}
                          </Text>
                        )}
                      />
                      <Text className="mt-2 text-xs italic text-justify text-gray-500">
                        TUNETALK users, please contact{" "}
                        {Platform.OS === "web" ? (
                          <Pressable
                            className="font-bold underline"
                            onPress={() => {
                              window.open(
                                "https://dealsifu.com/contact-us",
                                "_blank"
                              );
                            }}
                          >
                            <Text>support</Text>
                          </Pressable>
                        ) : (
                          <Text>support.</Text>
                        )}{" "}
                        first. SMS will come from MOVIDER. The countdown is just
                        an estimation.
                      </Text>
                    </Motion.View>
                  ) : null}

                  <Text className="mb-2 text-orange-500">Age Range*</Text>
                  <View className="mb-5">
                    <PickerAgeRange
                      selectedAgeRange={selectedAgeRange}
                      setSelectedAgeRange={setSelectedAgeRange}
                    />
                  </View>

                  <Text className="mb-2 text-orange-500">Gender*</Text>
                  <GenderSwitchSelecter
                    isGenderSelected={isGenderSelected}
                    setIsGenderSelected={setIsGenderSelected}
                    genderSwitch={genderSwitch}
                    setGenderSwitch={setGenderSwitch}
                    selectedGender={selectedGender}
                    setSelectedGender={setSelectedGender}
                    startX={16}
                    endX={118}
                  />

                  <ButtonPrimaryGoogleCustom
                    onPress={handleSubmit(handleGoogleSignUp)}
                    isDisabled={
                      loadingGoogleSU || !usernameValidated || !phoneValidated
                    }
                    loading={loadingGoogleSU}
                    buttonText="Sign up with Google"
                    customTailwind={
                      usernameValidated && phoneValidated
                        ? "w-full py-3 bg-white rounded hover:bg-gray-200 active:bg-gray-300"
                        : "w-full py-3 bg-gray-400 rounded"
                    }
                  />
                  <PrivacyNotice />
                </View>
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
        <ToastDefault ref={toastRef} />
      </Modal>

      <ButtonPrimaryGoogle
        onPress={() => {
          setModalVisible(true);
          setModalShowing(true);
        }}
        loading={false}
        buttonText="Sign up with Google"
      />
    </>
  );
}

const styles = StyleSheet.create({
  modalView: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  cell: {
    width: 40,
    height: 40,
    lineHeight: 32, // initially 38
    fontSize: 24,
    fontWeight: "300",
    textAlign: "center",
    borderWidth: 2,
    borderColor: "#9ca3af", // bg-gray-400
    backgroundColor: "#e5e7eb", // bg-gray-200
  },
  focusCell: {
    borderColor: "#f97316", // bg-orange-500
  },
});

// workaround, for react-native-web by default, it has border outline around TextInput when focused
// https://stackoverflow.com/questions/53639013/typescript-and-react-native-are-the-type-definitions-for-rn-styles-wrong
const rnw_outline_none: any = {
  web: {
    outlineStyle: "none",
  },
};
