import LogoWhatsapp from "./LogoWhatsapp";
import SunnyOutline from "./SunnyOutline";
import Moon from "./Moon";

// original Ionicons size divide by 4 to get tailwind matching size
// cannot use the size and color as variable for className for now
// works in localhost but not production

export default function Ionicons({ name, size, color }) {
    // const newSize = (Math.floor(size / 4)).toString();
    switch(name) {
      case "logo-whatsapp-white-5":
        return <LogoWhatsapp className="w-5 h-5 fill-white" />
      case "logo-whatsapp-black-5":
        return <LogoWhatsapp className="w-5 h-5 fill-black" />
      case "sunny-outline-white-5":
        return <SunnyOutline className="w-5 h-5 stroke-white" />
      case "moon-black-5":
        return <Moon className="w-5 h-5 fill-black" />
      default:
        return <LogoWhatsapp className="w-5 h-5 fill-black" />
    }
}