import { View, Text, Pressable, Platform } from "react-native";
import { Motion } from "@legendapp/motion/";
import { useColorScheme } from "tailwindcss-react-native";
import { useLocation } from "react-router-dom"; // for web only

export default function GenderSwitchSelecter({
  isGenderSelected,
  setIsGenderSelected,
  genderSwitch,
  setGenderSwitch,
  selectedGender,
  setSelectedGender,
  startX,
  endX,
}: {
  isGenderSelected: boolean;
  setIsGenderSelected: React.Dispatch<React.SetStateAction<boolean>>;
  genderSwitch: number;
  setGenderSwitch: React.Dispatch<React.SetStateAction<number>>;
  selectedGender: string;
  setSelectedGender: React.Dispatch<React.SetStateAction<string>>;
  startX: number;
  endX: number;
}) {
  const { colorScheme } = useColorScheme();
  const location = Platform.OS === "web" ? useLocation() : null;
  const viewClassName =
    location?.pathname === "/email-signup" && colorScheme === "dark"
      ? "flex-row justify-around border-2 rounded border-grayish-600"
      : "flex-row justify-around border-2 border-gray-200 rounded";

  const motionClassName =
    location?.pathname === "/email-signup"
      ? "absolute left-[26px] top-[5.5px]"
      : "absolute left-[16px] top-[5.5px]";

  // code below not working so far, we use workaround code above
  //const motionClassName = `absolute left-[${startX}px] top-[5.5px]`;

  return (
    <View className="flex-col mb-5">
      {isGenderSelected && (
        <Motion.View
          className={motionClassName}
          animate={{
            x: genderSwitch * endX,
          }}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 300,
          }}
        >
          <View className="w-[85px] h-[30px] bg-orange-500 rounded"></View>
        </Motion.View>
      )}

      <View className={viewClassName}>
        <Pressable
          className="px-4 py-2.5 rounded"
          onPress={() => {
            setSelectedGender("male");
            setGenderSwitch(0);
            setIsGenderSelected(true);
          }}
        >
          <Text
            className={
              selectedGender === "male" && isGenderSelected
                ? "text-white"
                : "text-[#808080]"
            }
          >
            Male
          </Text>
        </Pressable>
        <Pressable
          className="px-3 py-2.5 rounded"
          onPress={() => {
            setSelectedGender("female");
            setGenderSwitch(1);
            setIsGenderSelected(true);
          }}
        >
          <Text
            className={
              selectedGender === "female" && isGenderSelected
                ? "text-white"
                : "text-[#808080]"
            }
          >
            Female
          </Text>
        </Pressable>
      </View>
    </View>
  );
}
