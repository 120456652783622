import { useState, useEffect } from "react";
import { supabase } from "../../utils/supabase";
import {
  View,
  Text,
  TextInput,
  Platform,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Pressable,
  ActivityIndicator,
} from "react-native";
import { ApiError, Session } from "@supabase/supabase-js";
import { useForm, Controller } from "react-hook-form";
import Avatar from "./Avatar";
import { firstLastNameRules, usernameRules } from "../FormHelper/formRules";
import { ButtonPrimary, ButtonPrimaryCustom } from "../Buttons";
import DarkSwitchButton from "../Buttons/DarkSwitchButton";
import { Ionicons } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import { useDiscourseStore, useModalStore } from "../../utils/store";
import AlertModal from "../AlertModal";
import ResetPasswordModal from "../ResetPasswordModal";
import Logo from "../Svg/LogoDealsifu";
import SupportButtonModal from "../Buttons/SupportButtonModal";
import { useColorScheme } from "tailwindcss-react-native";

export default function ProfilePage({ session }: { session: Session }) {
  const { colorScheme } = useColorScheme();

  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);
  const [loadingGetProfile, setLoadingGetProfile] = useState(false);
  const [loadingSignOut, setLoadingSignOut] = useState(false);
  const [usernameTextRed, setUsernameTextRed] = useState(false);

  const [fullNameVal, setFullNameVal] = useState("");
  const [usernameVal, setUsernameVal] = useState("");
  const [avatarUrlVal, setAvatarUrlVal] = useState("");

  const [usernameInputChanged, setUsernameInputChanged] = useState(false);
  const [fullNameInputChanged, setFullNameInputChanged] = useState(false);

  // ZUSTAND
  const redirectUrl = useDiscourseStore((state) => state.redirectUrl);
  const setRedirectUrl = useDiscourseStore((state) => state.setRedirectUrl);
  const setModalShowing = useModalStore((state) => state.setModalShowing);

  const [modalAlertVisible, setModalAlertVisible] = useState(false);

  const [sso, setSSO] = useState("");
  const [sig, setSIG] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [modalResetPasswordVisible, setModalResetPasswordVisible] =
    useState(false);

  // https://stackoverflow.com/questions/64306943/defaultvalues-of-react-hook-form-is-not-setting-the-values-to-the-input-fields-i
  // defaultValues are cached on the first render within the custom hook, hence first render cannot set values from external data, we use RESET
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      full_name: "",
      avatar_url: "",
    },
  });

  useEffect(() => {
    // for now better wait here (1 second) so that session is null, no need await keyword here apparently
    (async () => {
      await waitDbInsert(1000);
    })();

    if (session) getProfile();

    if (session) {
      const subscription = supabase
        .from(`profiles:id=eq.${session.user?.id}`)
        .on("UPDATE", (payload) => {
          let defaults = {
            username: payload.new.username,
            full_name: payload.new.full_name,
            avatar_url: payload.new.avatar_url,
          };
          reset(defaults);
          setUsernameVal(payload.new.username);
          setFullNameVal(payload.new.full_name);
          setAvatarUrlVal(payload.new.avatar_url);
        })
        .subscribe();

      return () => {
        supabase.removeSubscription(subscription);
      };
    }
  }, []); // TODO for mobile, we need [session] here for subscription to work well amongst phones, but we ommit for now, because works okay amongst browsers, and with omitting, we don't relaod when changing tabs in browser

  useEffect(() => {
    if (Platform.OS === "web") {
      const url = window.location.href;

      if (url.includes("sso=") && url.includes("&sig=")) {
        let parsedURL = Linking.parse(url);

        if (parsedURL.queryParams?.sso) {
          setSSO(parsedURL.queryParams?.sso as string);
        }
        if (parsedURL.queryParams?.sig) {
          setSIG(parsedURL.queryParams.sig as string);
        }
      }

      // only SSO above different, the rest should be the same for index at Profile and Auth pages

      // eg: /verify?token=3241413242qerwerewqgfqweqwerqasavsbsdafdsf&type=recovery&redirect_to=http://localhost:19006/
      if (url.includes("type=recovery")) {
        let parsedURL = Linking.parse(url.replace("#", "?")!); // because we want #access_token to become ?access_token so that we can query the params

        if (parsedURL.queryParams?.access_token) {
          setResetToken(parsedURL.queryParams?.access_token as string);
          setModalResetPasswordVisible(true);
          setModalShowing(true);
        }
        window.history.replaceState({}, document.title, "/" + ""); // clear the url bar from showing params in web
      }

      // we need this after clicking email verification (type=signup)
      if (url.includes("access_token") && url.includes("type=signup")) {
        let parsedURL = Linking.parse(url.replace("#", "?")!); // because we want #access_token to become ?access_token so that we can query the params

        if (parsedURL.queryParams?.refresh_token) {
          supabase.auth.signIn({
            refreshToken: parsedURL.queryParams.refresh_token as string,
          });
        }
        window.history.replaceState({}, document.title, "/" + ""); // clear the url bar from showing params in web
      }

      if (url.includes("error=")) {
        let parsedURL = Linking.parse(url.replace("#", "?")!); // because we want #error to become ?error so that we can query the params

        if (parsedURL.queryParams?.error_description) {
          toast.show(parsedURL.queryParams.error_description.toString(), {
            type: "warning",
            duration: 5000,
          });
        }

        window.history.replaceState({}, document.title, "/" + ""); // clear the url bar from showing params in web
        //location.reload(); // refresh page, because sometimes after code above icon for toggle dark mode and support disappear
      }
    }
  }, []);

  useEffect(() => {
    // For Discourse Login, because we cannot call window.open in authFunctionsGoogle due to redirect after successful login
    if (Platform.OS === "web") {
      if (redirectUrl !== "") {
        setModalAlertVisible(true);
        setModalShowing(true);
        // console.log(redirectUrl);
      }
    }
  }, [redirectUrl]);

  useEffect(() => {
    if (Platform.OS === "web" && sso !== "" && sig !== "") {
      // https://ultimatecourses.com/blog/using-async-await-inside-react-use-effect-hook
      (async () => {
        const { data, error: error_invoke } = await supabase.functions.invoke(
          "discourse-sso-loggedin",
          {
            body: JSON.stringify({ sso, sig }),
          }
        );

        // console.log("discourse-sso-loggedin data:", data);
        if (error_invoke)
          toast.show(error_invoke.message, {
            type: "warning",
          });
        if (data.error_description) {
          toast.show(data.error_description, {
            type: "warning",
          });
        }
        if (data.login_url) {
          // console.log(data.login_url);
          setRedirectUrl(data.login_url); // we will use modal with button, technically we don't need zustand anymore
        }
        window.history.replaceState({}, document.title, "/" + ""); // clear the url bar from showing params in web
      })();
    }
  }, [sig, sso]);

  // helper for Alert Modal
  const handleAlertModalExit = () => {
    setModalAlertVisible(false);
    setModalShowing(false);
    window.open(redirectUrl, "_blank"); // because the click action is coming from user, this will not result in default browser pop-up blocker
    setRedirectUrl(""); // because we don't persist zustand, this code may not be needed after all, but just put here to be safe
    // window.history.replaceState({}, document.title, "/" + ""); // clear the url bar from showing params in web
  };

  // https://stackoverflow.com/questions/22125865/wait-until-flag-true
  function waitDbInsert(howLong: number) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, howLong); // in milliseconds, 250 maybe too short
    });
  }

  async function getProfile() {
    try {
      setLoadingGetProfile(true);
      // we have to wait here, because if wait after calling supabase code below, the data will be static until the next call
      await waitDbInsert(2000); // our code below can actually run before we have fully insert our data (especially username), we wait just for 2000 milliseconds (two seconds)
      const user = supabase.auth.user();
      // if (!user) throw new Error("No user on the session!");
      if (!user) return;

      let { data, error, status } = await supabase
        .from("profiles")
        .select(`username, full_name, avatar_url`)
        .eq("id", user.id)
        .single();

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        // data null can be due to two reasons
        // (a) error 406 using normal email-password signup (postgres trigger on new user return 406 error)
        // (b) data not available yet

        // we try to update data based on metadata incase of (a)
        // we check if the meta data is of type "email-password signup" by checking for user.user_metadata.username
        if (data.username === null || data.full_name === null) {
          if (user.user_metadata.username) {
            updateProfileMeta({
              username: user.user_metadata.username,
              phone_num: user.user_metadata.phone_num,
              first_name: user.user_metadata.first_name,
              last_name: user.user_metadata.last_name,
              email: user.user_metadata.email,
              full_name: user.user_metadata.full_name,
            });
          }
        }

        // it's weird that we need this block of code to make data.avatar_url not null in the next code, setting waitDB for a bit longer may also help
        if (data.avatar_url) {
          //console.log("data avatar url", data.avatar_url);
        } else {
          // console.log("data avatar url NULL");
        }

        let defaults = {
          username: data.username ?? "Data not synced, click refresh icon...",
          full_name: data.full_name ?? "Data not synced, click refresh icon...",
          avatar_url: data.avatar_url ?? "",
        };
        reset(defaults);

        // toast.show("Profile refreshed!");
        setUsernameVal(data.username);
        setFullNameVal(data.full_name);
        setAvatarUrlVal(data.avatar_url);
        // console.log("profile data", data);
      }
    } catch (error) {
      toast.show((error as ApiError).message, {
        type: "warning",
      });
      // alert((error as ApiError).message);
    } finally {
      setLoadingGetProfile(false);
    }
  }

  async function updateProfile({
    username,
    full_name,
    avatar_url,
  }: {
    username: string;
    full_name: string;
    avatar_url: string;
  }) {
    try {
      setLoadingUpdateProfile(true);
      const user = supabase.auth.user();
      if (!user) throw new Error("No user on the session!");

      const updates = {
        id: user.id,
        username,
        full_name,
        avatar_url,
        updated_at: new Date(),
      };

      let { data, error } = await supabase.from("profiles").upsert(updates);

      if (error) {
        throw error;
      }

      if (data) {
        let defaults = {
          username: data[0].username,
          full_name: data[0].full_name,
          avatar_url: data[0].avatar_url,
        };
        reset(defaults);

        toast.show("Profile updated!", {
          type: "success",
        });
        Keyboard.dismiss();
      }
    } catch (error: any) {
      if (error.message.includes("violates unique constraint")) {
        toast.show("Sorry, username taken.", {
          type: "danger",
        });
        setUsernameTextRed(true);
      } else {
        toast.show((error as ApiError).message, {
          type: "warning",
        });
      }
    } finally {
      setLoadingUpdateProfile(false);
      setFullNameInputChanged(false);
      setUsernameInputChanged(false);
    }
  }

  async function updateProfileMeta({
    username,
    phone_num,
    first_name,
    last_name,
    email,
    full_name,
  }: {
    username: string | null;
    phone_num: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    full_name: string;
  }) {
    try {
      const user = supabase.auth.user();
      if (!user) throw new Error("No user on the session!");

      const updates = {
        id: user.id,
        username,
        phone_num,
        first_name,
        last_name,
        email,
        full_name,
        updated_at: new Date(),
      };

      const { error } = await supabase
        .from("profiles")
        .upsert(updates, { returning: "minimal" });

      if (error) {
        throw error;
      }
    } catch (error) {
      toast.show((error as ApiError).message);
    }
  }

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS !== "web" ? Keyboard.dismiss : () => {}}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        className="flex-col items-center justify-center h-full p-10 bg-white dark:bg-grayish-700"
      >
        <DarkSwitchButton />
        <SupportButtonModal />
        <View className="w-16 h-16 mb-6">
          <Logo />
        </View>
        <ResetPasswordModal
          modalResetPasswordVisible={modalResetPasswordVisible}
          setModalResetPasswordVisible={setModalResetPasswordVisible}
          resetToken={resetToken}
          setResetToken={setResetToken}
        />
        <AlertModal
          modalAlertVisible={modalAlertVisible}
          setModalAlertVisible={setModalAlertVisible}
          handleAlertModalExit={handleAlertModalExit}
          textPrimary="Forum will open in new tab."
          textSecondary='You may change your forum profile at dealsifu.com and click "Go to forum and sync profile".'
        />
        <View className="flex-col items-center justify-center w-full max-w-xs p-5 bg-gray-100 rounded dark:bg-grayish-800">
          <View className="w-full">
            <View className="relative justify-center mx-2">
              {/* <View className="w-6 h-6">
                <Logo />
              </View> */}
              <Text className="mb-6 text-xl font-semibold text-center text-gray-900 dark:text-white">
                Profile Settings
              </Text>
              {usernameVal === null ||
              fullNameVal === null ||
              avatarUrlVal === null ? (
                <View className="absolute -top-2 -right-4">
                  {loadingGetProfile ? (
                    <Pressable className="flex items-center justify-center w-8 h-8 rounded">
                      {colorScheme === "dark" ? (
                        <ActivityIndicator color="white" />
                      ) : (
                        <ActivityIndicator />
                      )}
                    </Pressable>
                  ) : (
                    <Pressable
                      className="flex items-center justify-center w-8 h-8 rounded"
                      onPress={() => {
                        if (session) {
                          getProfile();
                        }
                      }}
                    >
                      <Ionicons name="refresh" size={28} color="grey" />
                    </Pressable>
                  )}
                </View>
              ) : null}
            </View>

            <Avatar
              url={avatarUrlVal}
              onUpload={(url: string) => {
                setAvatarUrlVal(url);
                // if we call onUpload but url remain the same (case for image update), just reload the page
                // TODO for mobile - no longer needed
                if (avatarUrlVal === url) {
                  // no longer needed
                  // if (Platform.OS === "web") document.location.reload();
                } else {
                  updateProfile({
                    username: usernameVal,
                    full_name: fullNameVal,
                    avatar_url: url,
                  });
                }
              }}
            />
            <Text className="mb-2 text-orange-500">Email (unchangable)</Text>
            <TextInput
              className="p-2.5 mb-6 bg-gray-200 dark:bg-grayish-600 text-gray-400 rounded caret-black dark:caret-white"
              value={session?.user?.email}
              editable={false}
              style={Platform.select(rnw_outline_none)}
            />
            <View className="flex-row items-center mb-2">
              <Text className="text-orange-500">Username</Text>
              {usernameInputChanged && (
                <Text className="text-orange-500"> (change detected!)</Text>
              )}
              {loadingGetProfile &&
                (colorScheme === "dark" ? (
                  <ActivityIndicator
                    className="ml-1.5"
                    size={16}
                    color="white"
                  />
                ) : (
                  <ActivityIndicator className="ml-1.5" size={16} />
                ))}
            </View>

            <Controller
              control={control}
              rules={usernameRules}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  className={
                    usernameTextRed
                      ? "p-2.5 mb-6 bg-gray-200 dark:bg-grayish-600 text-red-500 caret-black rounded"
                      : "p-2.5 mb-6 bg-gray-200 dark:bg-grayish-600 caret-black text-black rounded dark:text-white"
                  }
                  onBlur={onBlur}
                  onChangeText={(e) => {
                    onChange(e);
                    if (e !== usernameVal) {
                      setUsernameInputChanged(true);
                    } else {
                      setUsernameInputChanged(false);
                    }
                    if (usernameTextRed === true) {
                      setUsernameTextRed(false);
                    }
                  }}
                  value={value}
                />
              )}
              name="username"
            />
            {errors.username && (
              <Text className="mb-6 -mt-5 text-red-700 dark:text-red-100">
                {errors.username.message}
              </Text>
            )}

            <View className="flex-row items-center mb-2">
              <Text className="text-orange-500">Full Name</Text>
              {fullNameInputChanged && (
                <Text className="text-orange-500"> (change detected!)</Text>
              )}
              {loadingGetProfile &&
                (colorScheme === "dark" ? (
                  <ActivityIndicator
                    className="ml-1.5"
                    size={16}
                    color="white"
                  />
                ) : (
                  <ActivityIndicator className="ml-1.5" size={16} />
                ))}
            </View>
            <Controller
              control={control}
              rules={firstLastNameRules}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  className="p-2.5 mb-6 bg-gray-200 dark:bg-grayish-600 dark:text-white rounded caret-black dark:caret-white"
                  onBlur={onBlur}
                  onChangeText={(e) => {
                    onChange(e);
                    if (e !== fullNameVal) {
                      setFullNameInputChanged(true);
                    } else {
                      setFullNameInputChanged(false);
                    }
                  }}
                  value={value}
                />
              )}
              name="full_name"
            />
            {errors.full_name && (
              <Text className="mb-6 -mt-5 text-red-700 dark:text-red-100">
                {errors.full_name.message}
              </Text>
            )}

            <ButtonPrimaryCustom
              onPress={handleSubmit(updateProfile)}
              isDisabled={
                loadingUpdateProfile ||
                (!usernameInputChanged && !fullNameInputChanged)
              }
              loading={loadingUpdateProfile}
              buttonText={
                usernameInputChanged || fullNameInputChanged
                  ? "Update (click to save)"
                  : "Update"
              }
              customTailwind={
                usernameInputChanged || fullNameInputChanged
                  ? "w-full py-2.5 mb-4 bg-orange-600 rounded hover:bg-orange-700 active:bg-orange-800"
                  : "w-full py-2.5 mb-4 bg-gray-400 rounded"
              }
            />
            <ButtonPrimary
              onPress={() => {
                setLoadingSignOut(true);
                supabase.auth.signOut();
              }}
              loading={loadingSignOut}
              buttonText="Sign Out"
            />
            <Pressable
              className="mt-3"
              onPress={() => {
                // https://meta.discourse.org/t/create-a-discourseconnect-login-link/109290
                const url = `https://forum.dealsifu.com/session/sso?return_path=https://forum.dealsifu.com/u/${usernameVal}/preferences/account/`;
                Linking.openURL(url);
                // if (Platform.OS == "web") {
                //   // window.open(url, "_blank");
                //   window.open(url);
                // } else {
                //   Linking.openURL(url);
                // }
              }}
            >
              {({ pressed }) => (
                <Text
                  className={
                    pressed
                      ? "text-sm text-center text-orange-700"
                      : "text-sm text-center text-orange-500"
                  }
                >
                  Go to forum and sync profile{" "}
                  <Ionicons name="open-outline" size={18} />
                </Text>
              )}
            </Pressable>
            <Text className="mt-1 text-xs italic text-center text-gray-600 dark:text-gray-200">
              (this will sync profile on dealsifu &amp; forum.dealsifu, you may
              need to manually reload the browser of the forum page to see new
              avatar image)
            </Text>
          </View>
          {/* End Form */}
        </View>
        {/* End Login Inner Container */}
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
}

// workaround, for react-native-web by default, it has border outline around TextInput when focused
// https://stackoverflow.com/questions/53639013/typescript-and-react-native-are-the-type-definitions-for-rn-styles-wrong
const rnw_outline_none: any = {
  web: {
    outlineStyle: "none",
  },
};
