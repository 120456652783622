import { supabase } from "../../utils/supabase";
import { Platform } from "react-native";

function displayToast(toastRef: any, msg: string, msgType: string) {
  toastRef // if null, use root toast
    ? toastRef.current.show(msg, { type: msgType })
    : toast.show(msg, { type: msgType });
}

export async function handleCheckUsername(
  getValues: any,
  trigger: any,
  toastRef: any,
  setLoading: any,
  setUsernameTextRed: any,
  setUsernameValidated: any
) {
  const usernameToCheck = getValues("username");
  const passedValidation = await trigger("username"); // https://react-hook-form.com/api/useform/trigger
  if (!passedValidation) return;

  // if passed validation, run code below
  setLoading(true);
  try {
    // data => true or false
    const { data: usernameExisted, error }: any = await supabase.rpc(
      "username_exist",
      {
        username: usernameToCheck,
      }
    );
    if (error) displayToast(toastRef, error.message, "danger");
    if (usernameExisted === true) {
      setUsernameTextRed(true);
      displayToast(toastRef, "Username taken, try another one", "danger");
    } else if (usernameExisted === false) {
      setUsernameValidated(true);
    }
  } finally {
    setLoading(false);
  }
}

export enum ResponseType {
  json,
  text,
  arrayBuffer,
  blob,
}

export type FunctionInvokeOptions = {
  headers?: { [key: string]: string };
  body?:
    | Blob
    | BufferSource
    | FormData
    | URLSearchParams
    | ReadableStream<Uint8Array>
    | string;
  responseType?: keyof typeof ResponseType;
};

// mostly from supabase.functions.invoke - to overcome Android RN "TypeError: Network request failed"
// but cannot use for browser - will result in CORS error
async function invoke<T = any>(
  functionName: string,
  invokeOptions?: FunctionInvokeOptions
): Promise<{ data: T; error: null } | { data: null; error: Error }> {
  try {
    const { headers, body } = invokeOptions ?? {};
    const fetchUrl = `${process.env.RN_SUPABASE_FN_URL}/${functionName}`;
    const response = await fetch(fetchUrl, {
      method: "POST",
      headers: Object.assign(
        {},
        {
          "Content-type": "application/json",
          Authorization: `Bearer ${process.env.RN_SUPABASE_ANON_KEY}`,
        },
        headers
      ),
      body,
    });

    const isRelayError = response.headers.get("x-relay-error");
    if (isRelayError && isRelayError === "true") {
      return { data: null, error: new Error(await response.text()) };
    }

    let data;
    const { responseType } = invokeOptions ?? {};
    if (!responseType || responseType === "json") {
      data = await response.json();
    } else if (responseType === "arrayBuffer") {
      data = await response.arrayBuffer();
    } else if (responseType === "blob") {
      data = await response.blob();
    } else {
      data = await response.text();
    }

    return { data, error: null };
  } catch (error: any) {
    return { data: null, error };
  }
}

export async function handleRequestOtp(
  getValues: any,
  trigger: any,
  toastRef: any,
  setLoading: any,
  setPhoneValidated: any,
  setMvdRequestId: any,
  setDisplayPinPad: any,
  setIsShowingTimer: any
) {
  const phoneToVerify = getValues("phoneNumber");
  const passedValidation = await trigger("phoneNumber"); // https://react-hook-form.com/api/useform/trigger
  if (!passedValidation) return;

  // if passed validation, run code below
  setLoading(true);

  let response = {};

  if (Platform.OS === "android") {
    response = await invoke("mvd-request-otp", {
      body: JSON.stringify({ phoneToVerify }),
    });
  } else {
    response = await supabase.functions.invoke("mvd-request-otp", {
      body: JSON.stringify({ phoneToVerify }),
    });
  }

  const { data, error }: any = response;
  if (error) displayToast(toastRef, error.message, "danger"); // error from error to invoke, not data.error_description
  // console.log(JSON.stringify(data));

  // if for any reason, the data is null, we handle it
  if (data === null) {
    displayToast(
      toastRef,
      "Error code: MVDREQUEST-FETCH. Please try again or contact support!",
      "danger"
    );
    setLoading(false);
    return;
  }

  if (data.error_description) {
    switch (data.error_description) {
      case "The receiver number is verifying.":
        displayToast(toastRef, "Request every 3 mins only.", "warning");
        break;
      case "Receivers number are invalid.":
        displayToast(toastRef, "Phone number is invalid.", "danger");
        break;
      case "Phone number already verified!":
        displayToast(toastRef, "Phone number already verified!", "success");
        setPhoneValidated(true);
        break;
      default:
        displayToast(toastRef, data.error_description, "danger");
    }
  } else {
    if (data.request_id) {
      setMvdRequestId(data.request_id);
      setDisplayPinPad(true);
      setIsShowingTimer(true);
    }
  }
  setLoading(false);
}

export async function handleVerifyOtp(
  otpValue: string,
  mvdRequestId: string,
  getValues: any,
  toastRef: any,
  setLoading: any,
  setPhoneValidated: any,
  setMvdRequestId: any,
  setOtpValue: any,
  setDisplayPinPad: any
) {
  const phoneToVerify = getValues("phoneNumber");
  setLoading(true);

  let response = {};
  if (Platform.OS === "android") {
    response = await invoke("mvd-verify-otp", {
      body: JSON.stringify({ mvdRequestId, otpValue, phoneToVerify }),
    });
  } else {
    response = await supabase.functions.invoke("mvd-verify-otp", {
      body: JSON.stringify({ mvdRequestId, otpValue, phoneToVerify }),
    });
  }
  const { data, error }: any = response;
  if (error) displayToast(toastRef, error.message, "danger"); // error from error to invoke, not data.error_description
  // console.log(JSON.stringify(data));

  // if for any reason, the data is null, we handle it
  if (data === null) {
    displayToast(
      toastRef,
      "Error code: MVDVERIFY-FETCH. Please try again or contact support!",
      "danger"
    );
    setLoading(false);
    return;
  }
  if (data.error_description) {
    switch (data.error_description) {
      case "Request id and code are not matched.":
        displayToast(toastRef, "Incorrect PIN, please check SMS.", "danger");
        break;
      case "Verify SMS has expired.":
        displayToast(
          toastRef,
          "PIN has expired, please request a new one.",
          "warning"
        );
        break;
      default:
        displayToast(toastRef, data.error_description, "danger");
    }
  } else {
    if (data.request_id === mvdRequestId) {
      setMvdRequestId(data.request_id);
      setPhoneValidated(true);
      setOtpValue("");
      setDisplayPinPad(false);
    }
  }
  setLoading(false);
}
