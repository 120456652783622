import { useState, useRef, useEffect } from "react";
import {
  Modal,
  StyleSheet,
  Text,
  Pressable,
  View,
  Platform,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom"; // for web only
import {
  emailRules,
  fullNameRules,
  contactDescriptionRules,
} from "../FormHelper/formRules";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { ButtonPrimaryGreen } from "../Buttons";
import { modalAnimationType } from "../../utils/deviceInfo";
import Logo from "../Svg/LogoDealsifu";
import * as Linking from "expo-linking";
import { supabase } from "../../utils/supabase";
import ToastDefault from "../Toast/ToastDefault";

export default function SupportModal({
  modalVisible,
  setModalVisible,
  setModalShowing,
}: any) {
  const [loadingSubmitSupport, setLoadingSubmitSupport] = useState(false);
  const maxChar = contactDescriptionRules.maxLength.value;
  const [charLeft, setCharLeft] = useState(maxChar);

  const toastRef: any = useRef();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      description: "",
    },
  });

  // metadata full_name is available for both sign-up via google and email-password
  useEffect(() => {
    const user = supabase.auth.user();
    if (user) {
      let defaults = {
        name: user.user_metadata.full_name ? user.user_metadata.full_name : "",
        email: user.email ? user.email : "",
        description: "",
      };
      reset(defaults);
    }
  }, []);

  // data from react-hook-form
  // data =  {email: "",.....}
  async function submitSupport({ name, email, description }: any) {
    // setLoadingSubmitSupport(true);
    const waUrl = "https://api.whatsapp.com/send?phone=";
    const bizNum = "60193009191";
    // https://www.w3schools.com/tags/ref_urlencode.ASP
    const encodedDescription = encodeURIComponent(description);
    const text = `Hi, Dealsifu Support!%0AMy name is ${name} and my email is ${email}.%0AReason for contacting support:%0A${encodedDescription}`;
    const fullWaUrl = waUrl + bizNum + "&text=" + text;

    if (Platform.OS == "web") {
      window.open(fullWaUrl, "_blank");
    } else {
      Linking.openURL(fullWaUrl);
    }
    // setLoadingSubmitSupport(false);
  }

  const navigate = Platform.OS === "web" ? useNavigate() : null;
  const location = Platform.OS === "web" ? useLocation() : null;

  return (
    <Modal
      animationType={modalAnimationType}
      transparent={true}
      statusBarTranslucent={true}
      visible={modalVisible}
      onRequestClose={() => {
        // this is for Android back button only
        setModalVisible(false);
        setModalShowing(false);
      }}
    >
      <SafeAreaProvider>
        <SafeAreaView className="flex-1 bg-black/50">
          <TouchableWithoutFeedback
            onPress={Platform.OS !== "web" ? Keyboard.dismiss : () => {}}
          >
            <KeyboardAvoidingView
              behavior={"padding"}
              className="items-center justify-center flex-1"
            >
              <View
                style={styles.modalView}
                className="flex-col items-center justify-center p-10 bg-gray-100 rounded w-[360px]"
              >
                <Pressable
                  className="absolute top-3 right-3"
                  onPress={() => {
                    if (
                      Platform.OS === "web" &&
                      location?.pathname === "/contact-us"
                    ) {
                      navigate ? navigate("/") : null;
                    } else {
                      setModalVisible(false);
                    }
                    setModalShowing(false);
                  }}
                >
                  <Ionicons name="md-close-sharp" size={30} color="black" />
                </Pressable>

                <View className="w-full">
                  <View className="flex-row mb-6 ">
                    <Text className="text-2xl font-semibold text-gray-900">
                      Contact Support
                    </Text>
                    <View className="ml-2 w-7 h-7">
                      <Logo />
                    </View>
                    <Text className="text-2xl">🤙🏻</Text>
                  </View>
                  <Text className="mb-3">
                    This form will be submitted via{" "}
                    <Text className="font-bold">Whatsapp</Text>.
                  </Text>
                  <Text className="mb-3">
                    If you prefer another channel, you can{" "}
                    {Platform.OS === "web" ? (
                      <Text className="font-bold">
                        <a href="mailto:support@dealsifu.com">email</a>
                      </Text>
                    ) : (
                      <Pressable
                        className=""
                        onPress={() => {
                          Linking.openURL("mailto:support@dealsifu.com");
                        }}
                      >
                        <Text>email</Text>
                      </Pressable>
                    )}{" "}
                    us at{" "}
                    <Text className="font-bold">support@dealsifu.com</Text>.
                  </Text>
                  <Text className="mb-5">
                    We will try to reply to you the soonest.
                  </Text>

                  <Controller
                    control={control}
                    rules={fullNameRules}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        className="p-2.5 mb-5 bg-gray-200  caret-black rounded"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        keyboardType={"default"}
                        placeholder={"Enter full name"}
                        placeholderTextColor="gray"
                      />
                    )}
                    name="name"
                  />
                  {errors.name && (
                    <Text className="mb-5 -mt-4 text-red-700">
                      {errors.name.message}
                    </Text>
                  )}

                  <Controller
                    control={control}
                    rules={emailRules}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        className="p-2.5 mb-5 bg-gray-200  caret-black rounded"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        keyboardType={"default"}
                        placeholder={"Enter email"}
                        placeholderTextColor="gray"
                      />
                    )}
                    name="email"
                  />
                  {errors.email && (
                    <Text className="mb-5 -mt-4 text-red-700">
                      {errors.email.message}
                    </Text>
                  )}

                  <Controller
                    control={control}
                    rules={contactDescriptionRules}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        className="p-2.5 mb-5 bg-gray-200  caret-black rounded"
                        multiline
                        numberOfLines={5}
                        onBlur={onBlur}
                        onChangeText={(text) => {
                          const remainingChar = maxChar - text.length;
                          if (remainingChar < 0) {
                            // console.log("input frozen because no onChange(text)");
                          } else {
                            onChange(text);
                            setCharLeft(maxChar - text.length);
                          }
                        }}
                        value={value}
                        keyboardType={"default"}
                        placeholder={
                          "Describe reason for contacting support. Potential issues: (a) sign in / sign up at dealsifu.com (b) profile at dealsifu.com (c) issues at forum (forum.dealsifu.com) (d) others"
                        }
                        placeholderTextColor="gray"
                      />
                    )}
                    name="description"
                  />
                  {charLeft === 0 ? (
                    <Text className="mb-5 -mt-4 text-xs text-right text-red-700">
                      {charLeft} character left
                    </Text>
                  ) : charLeft === 1 ? (
                    <Text className="mb-5 -mt-4 text-xs text-right">
                      {charLeft} character left
                    </Text>
                  ) : (
                    <Text className="mb-5 -mt-4 text-xs text-right">
                      {charLeft} characters left
                    </Text>
                  )}

                  {errors.description && (
                    <Text className="mb-5 text-left text-red-700 -mt-9">
                      {errors.description.message}
                    </Text>
                  )}

                  <ButtonPrimaryGreen
                    onPress={handleSubmit(submitSupport)}
                    isDisabled={loadingSubmitSupport}
                    loading={loadingSubmitSupport}
                    buttonText="Submit via Whatsapp"
                  />
                </View>
              </View>
            </KeyboardAvoidingView>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      </SafeAreaProvider>
      <ToastDefault ref={toastRef} />
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalView: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
