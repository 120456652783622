import { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  Platform,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import { supabase } from "../../utils/supabase";
import { useForm, Controller } from "react-hook-form";
import * as Linking from "expo-linking";
import GoogleSignUpModal from "./GoogleSignUpModal";
import EmailSignUpModal from "./EmailSignUpModal";
import {
  signInUpWithGoogleWeb,
  signInUpWithGoogleMobile,
} from "./authFunctionsGoogle";
import { emailRules, passwordRules } from "../FormHelper/formRules";
import {
  ButtonPrimary,
  ButtonPrimaryGoogle,
  ButtonTextCustomOrange,
  ButtonTextCustomOrangeIonicons,
} from "../Buttons";
import DarkSwitchButton from "../Buttons/DarkSwitchButton";
import Logo from "../Svg/LogoDealsifu";
import RequestResetPasswordModal from "./RequestResetPasswordModal";
import ResetPasswordModal from "../ResetPasswordModal";
import SupportButtonModal from "../Buttons/SupportButtonModal";
import { Motion } from "@legendapp/motion/";
import { useModalStore } from "../../utils/store";

export default function AuthPage() {
  // SI => Sign In
  const [modalResetPasswordVisible, setModalResetPasswordVisible] =
    useState(false);
  const [loadingEmailSI, setLoadingEmailSI] = useState(false);
  const [loadingGoogleSI, setLoadingGoogleSI] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [displayEmailSI, setDisplayEmailSI] = useState(false);

  // ZUSTAND
  const setModalShowing = useModalStore((state) => state.setModalShowing);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (Platform.OS === "web") {
      const url = window.location.href;

      if (url.includes("sso=") && url.includes("&sig=")) {
        toast.show("Forum will open in new tab after a successful sign in.");
      }

      // only SSO above different, the rest should be the same for index at Profile and Auth pages

      // eg: /verify?token=3241413242qerwerewqgfqweqwerqasavsbsdafdsf&type=recovery&redirect_to=http://localhost:19006/
      if (url.includes("type=recovery")) {
        let parsedURL = Linking.parse(url.replace("#", "?")!); // because we want #access_token to become ?access_token so that we can query the params

        if (parsedURL.queryParams?.access_token) {
          setResetToken(parsedURL.queryParams?.access_token as string);
          setModalResetPasswordVisible(true);
          setModalShowing(true);
        }
        window.history.replaceState({}, document.title, "/" + ""); // clear the url bar from showing params in web
      }

      // we need this after clicking email verification (type=signup)
      if (url.includes("access_token") && url.includes("type=signup")) {
        let parsedURL = Linking.parse(url.replace("#", "?")!); // because we want #access_token to become ?access_token so that we can query the params

        if (parsedURL.queryParams?.refresh_token) {
          supabase.auth.signIn({
            refreshToken: parsedURL.queryParams.refresh_token as string,
          });
        }
        window.history.replaceState({}, document.title, "/" + ""); // clear the url bar from showing params in web
      }

      if (url.includes("error=")) {
        let parsedURL = Linking.parse(url.replace("#", "?")!); // because we want #error to become ?error so that we can query the params

        if (parsedURL.queryParams?.error_description) {
          toast.show(parsedURL.queryParams.error_description.toString(), {
            type: "warning",
            duration: 5000,
          });
        }

        window.history.replaceState({}, document.title, "/" + ""); // clear the url bar from showing params in web
        //location.reload(); // refresh page, because sometimes after code above icon for toggle dark mode and support disappear
      }
    }
  }, []);

  // data from react-hook-form
  // data =  {email: "" , password: "" }
  async function handleSignInWithEmail({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    setLoadingEmailSI(true);

    const { error } = await supabase.auth.signIn({
      email: email,
      password: password,
    });

    if (error) toast.show(error.message, { type: "warning" });
    setLoadingEmailSI(false);
  }

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS !== "web" ? Keyboard.dismiss : () => {}}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        className="flex-col items-center justify-center h-full p-10 bg-white dark:bg-grayish-700"
      >
        {/* <Image
          className="w-20 h-20 mb-6"
          source={require("../../assets/favicon.png")}
        /> */}

        <DarkSwitchButton />
        <SupportButtonModal />
        <View className="w-16 h-16 mb-6">
          <Logo />
        </View>
        <View className="flex-col items-center justify-center w-full max-w-xs p-5 bg-gray-100 rounded dark:bg-grayish-800">
          <View className="flex-row">
            <Text className="mb-6 text-3xl font-bold text-gray-900 dark:text-white">
              deal
            </Text>
            <Text className="mb-6 text-3xl font-bold text-gray-900 dark:text-white">
              sifu
            </Text>
          </View>

          {!displayEmailSI && (
            <Text className="mb-3 text-gray-900 dark:text-white">
              Returning user:
            </Text>
          )}

          <ButtonPrimaryGoogle
            onPress={() => {
              Platform.OS === "web"
                ? signInUpWithGoogleWeb(
                    setLoadingGoogleSI,
                    null,
                    null,
                    null,
                    null
                  )
                : signInUpWithGoogleMobile(
                    setLoadingGoogleSI,
                    null,
                    null,
                    null,
                    null
                  );
            }}
            isDisabled={loadingGoogleSI}
            loading={loadingGoogleSI}
            buttonText="Sign in with Google"
            marginTailwind="mb-6"
          />

          {!displayEmailSI && (
            <Text className="mb-3 text-gray-900 dark:text-white">
              New user? Register now:
            </Text>
          )}

          <GoogleSignUpModal />
          <ResetPasswordModal
            modalResetPasswordVisible={modalResetPasswordVisible}
            setModalResetPasswordVisible={setModalResetPasswordVisible}
            resetToken={resetToken}
            setResetToken={setResetToken}
          />

          {!displayEmailSI ? (
            <View className="relative w-full my-6">
              <ButtonTextCustomOrange
                onPress={() => {
                  setDisplayEmailSI(true);
                }}
                buttonText="Not using Gmail?"
                twPressable="absolute right-0"
              />
              <ButtonTextCustomOrangeIonicons
                onPress={() => {
                  const url = "https://forum.dealsifu.com";
                  if (Platform.OS == "web") {
                    window.open(url, "_blank");
                  } else {
                    Linking.openURL(url);
                  }
                }}
                buttonText="Go to Forum "
                twPressable="absolute left-0"
                iconName="open-outline"
                iconSize={17}
              />
            </View>
          ) : null}

          {displayEmailSI ? (
            <Motion.View
              key="A"
              className="w-full mb-1"
              initial={{ y: 0 }}
              animate={{ y: 5 }}
              transition={{
                type: "spring",
                damping: 20,
                stiffness: 1000,
                mass: 2,
              }}
            >
              <View className="flex-row w-full my-6">
                <View className="h-4 border-b-2 border-gray-400 basis-2/5"></View>
                <View className="relative h-4 basis-1/5">
                  <Text className="absolute -bottom-1.5 left-4 ml-0.5 text-gray-500">
                    OR
                  </Text>
                </View>
                <View className="h-4 border-b-2 border-gray-400 basis-2/5"></View>
              </View>
              {/* Horizontal line - "Simple version" */}
              {/* <View className="w-full h-4 mt-3 border-b-2 border-gray-400">
          <Text className="text-orange-100"></Text>
          </View> */}
              {/* Start Form */}
              <View className="w-full ">
                <Text className="mb-2 text-orange-500">Email</Text>
                <Controller
                  control={control}
                  rules={emailRules}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      className="p-2.5 mb-6 text-black bg-gray-200 rounded dark:text-white dark:bg-grayish-600 dark:caret-white caret-black"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      keyboardType="email-address"
                      placeholder="Enter your registered email"
                      placeholderTextColor="gray"
                    />
                  )}
                  name="email"
                />
                {errors.email && (
                  <Text className="mb-6 -mt-5 text-red-700 dark:text-red-100">
                    {errors.email.message}
                  </Text>
                )}

                <Text className="mb-2 text-orange-500">Password</Text>
                <Controller
                  control={control}
                  rules={passwordRules}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      className="p-2.5 mb-6 text-black bg-gray-200 rounded dark:text-white dark:bg-grayish-600 dark:caret-white caret-black"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      secureTextEntry={true}
                      placeholder={"Enter your secret password"}
                      placeholderTextColor="gray"
                    />
                  )}
                  name="password"
                />
                {errors.password && (
                  <Text className="mb-6 -mt-5 text-red-700 dark:text-red-100">
                    {errors.password.message}
                  </Text>
                )}

                <ButtonPrimary
                  onPress={handleSubmit(handleSignInWithEmail)}
                  isDisabled={loadingEmailSI}
                  loading={loadingEmailSI}
                  buttonText="Sign in with Email"
                />
              </View>
              {/* End Form */}

              <View className="relative w-full my-6">
                <RequestResetPasswordModal />
                <EmailSignUpModal />
              </View>
            </Motion.View>
          ) : null}
        </View>
        {/* End Login Inner Container */}
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
}
