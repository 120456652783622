// REFERENCES:
// https://blog.terrible.dev/Dynamically-changing-the-site-theme-meta-tag/
// https://css-tricks.com/meta-theme-color-and-trickery/
// https://stackoverflow.com/questions/72190986/react-native-how-to-reload-colors-based-on-dark-mode-and-light-mode
// import { Ionicons } from "@expo/vector-icons";
import Ionicons from "../Svg/Ionicons";
import { useState, useEffect } from "react";
import { useColorScheme } from "tailwindcss-react-native";
import { Pressable, View } from "react-native";
import { StatusBar } from "expo-status-bar";
import { useColorScheme as useColorSchemeSystem, Platform } from "react-native";
import { useModalStore } from "../../utils/store";

const DarkSwitchButton = () => {
  const { colorScheme, setColorScheme } = useColorScheme();
  const colorSchemeSystem = useColorSchemeSystem();
  const [mounted, setMounted] = useState(false);

  // ZUSTAND (we need this due to safari browser notch background color behavior)
  const modalShowing = useModalStore((state) => state.modalShowing);
  const modalTwoShowing = useModalStore((state) => state.modalTwoShowing);

  const darkColor = "#3a3a3a"; // grayish-700
  const darkColorModal = "#1d1d1d"; // use ColorSlurp app
  const darkColorAlertModal = "#0e0e0e"; // use ColorSlurp app

  // not working if choose pure white "#ffffff"
  // a bug not allowing to use pure white? other color okay
  // initial color feed into app.json "themeColor": "#ffffff" =>
  // the color itself has no impact, but the code has to be there to ensure meta[name="theme-color"] exist
  const lightColor = "#fffffe";
  const lightColorModal = "#7f7f7f"; // use ColorSlurp app
  const lightColorAlertModal = "#3f3f3f"; // use ColorSlurp app

  useEffect(() => {
    setMounted(true);
    setColorScheme(colorSchemeSystem);
  }, [colorSchemeSystem]);

  useEffect(() => {
    if (colorScheme === "dark") {
      Platform.OS === "web"
        ? document
            .querySelector('meta[name="theme-color"]')
            ?.setAttribute("content", darkColor)
        : null;
    } else {
      Platform.OS === "web"
        ? document
            .querySelector('meta[name="theme-color"]')
            ?.setAttribute("content", lightColor)
        : null;
    }
  }, [colorScheme]);

  useEffect(() => {
    if (modalShowing) {
      if (colorScheme === "dark") {
        Platform.OS === "web"
          ? document
              .querySelector('meta[name="theme-color"]')
              ?.setAttribute("content", darkColorModal)
          : null;
      } else {
        Platform.OS === "web"
          ? document
              .querySelector('meta[name="theme-color"]')
              ?.setAttribute("content", lightColorModal)
          : null;
      }
    } else {
      if (colorScheme === "dark") {
        Platform.OS === "web"
          ? document
              .querySelector('meta[name="theme-color"]')
              ?.setAttribute("content", darkColor)
          : null;
      } else {
        Platform.OS === "web"
          ? document
              .querySelector('meta[name="theme-color"]')
              ?.setAttribute("content", lightColor)
          : null;
      }
    }
  }, [modalShowing]);

  // this is assuming when AlertModal is closed, we close the parent modal / in-between modal as well
  useEffect(() => {
    if (modalTwoShowing) {
      if (colorScheme === "dark") {
        Platform.OS === "web"
          ? document
              .querySelector('meta[name="theme-color"]')
              ?.setAttribute("content", darkColorAlertModal)
          : null;
      } else {
        Platform.OS === "web"
          ? document
              .querySelector('meta[name="theme-color"]')
              ?.setAttribute("content", lightColorAlertModal)
          : null;
      }
    } else {
      // we can't do darkColorModal/brightColorModal here because changing this attribute back-to-back with modalShowing (useEffect above)
      // will result in color stucked at darkColorModal instead of the desired darkColor when we close the child modal and parent modal back-to-back programatically
      if (colorScheme === "dark") {
        Platform.OS === "web"
          ? document
              .querySelector('meta[name="theme-color"]')
              ?.setAttribute("content", darkColor)
          : null;
      } else {
        Platform.OS === "web"
          ? document
              .querySelector('meta[name="theme-color"]')
              ?.setAttribute("content", lightColor)
          : null;
      }
    }
  }, [modalTwoShowing]);

  // const handleColorScheme = useCallback(({ colorScheme1 }) => {
  //   //colorScheme1 === "dark" ? setColorScheme("light") : setColorScheme("dark");
  //   console.log(colorScheme1);
  // }, []);

  // useEffect(() => {
  //   const apperance = Appearance.addChangeListener(handleColorScheme);
  //   return () => {
  //     apperance.remove();
  //   };
  // }, [handleColorScheme]);

  const renderThemeChanger = () => {
    if (!mounted) return null;
    // this will get re-render if we change the system color (system color tie to colorScheme)
    if (colorScheme === "dark") {
      return <Ionicons name="sunny-outline-white-5" size={20} color="white" />;
    } else {
      return <Ionicons name="moon-black-5" size={20} color="black" />;
    }
  };

  return (
    <>
      <StatusBar
        backgroundColor={colorScheme === "dark" ? darkColor : lightColor}
        style="auto"
      />
      <View className="absolute top-5 right-5">
        <Pressable
          className="flex items-center justify-center bg-gray-100 rounded dark:bg-grayish-500 w-11 h-11"
          onPress={() => {
            colorScheme === "dark"
              ? setColorScheme("light")
              : setColorScheme("dark");
          }}
        >
          {renderThemeChanger()}
        </Pressable>
      </View>
    </>
  );
};

export default DarkSwitchButton;
