import { useState, useEffect } from "react";
import {
  Text,
  View,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
} from "react-native";
import GoogleSignUpModal from "../AuthPage/GoogleSignUpModal";

import {
  ButtonPrimaryGoogle,
  ButtonTextCustomOrange,
  ButtonTextCustomOrangeIonicons,
} from "../Buttons";
import DarkSwitchButton from "../Buttons/DarkSwitchButton";
import Logo from "../Svg/LogoDealsifu";
import { useModalStore } from "../../utils/store";
import SupportButton from "../Buttons/SupportButton";
import SupportModal from "./SupportModal";

export default function ContactPage() {
  const [modalVisible, setModalVisible] = useState(false);

  // ZUSTAND
  const setModalShowing = useModalStore((state) => state.setModalShowing);

  useEffect(() => {
    setModalVisible(true);
    setModalShowing(true);
  }, []);

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS !== "web" ? Keyboard.dismiss : () => {}}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        className="flex-col items-center justify-center h-full p-10 bg-white dark:bg-grayish-700"
      >
        <DarkSwitchButton />
        <SupportButton />
        {/* Modal is the crucial element here, the rest for background only */}
        <SupportModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          setModalShowing={setModalShowing}
        />
        {/* End Modal - Modal is the crucial element here, the rest for background only */}

        <View className="w-16 h-16 mb-6">
          <Logo />
        </View>
        <View className="flex-col items-center justify-center w-full max-w-xs p-5 bg-gray-100 rounded dark:bg-grayish-800">
          <View className="flex-row">
            <Text className="mb-6 text-3xl font-bold text-gray-900 dark:text-white">
              deal
            </Text>
            <Text className="mb-6 text-3xl font-bold text-gray-900 dark:text-white">
              sifu
            </Text>
          </View>

          <Text className="mb-3 text-gray-900 dark:text-white">
            Returning user:
          </Text>

          <ButtonPrimaryGoogle
            onPress={() => {}}
            buttonText="Sign in with Google"
            marginTailwind="mb-6"
          />

          <Text className="mb-3 text-gray-900 dark:text-white">
            New user? Register now:
          </Text>

          <GoogleSignUpModal />

          <View className="relative w-full my-6">
            <ButtonTextCustomOrange
              onPress={() => {}}
              buttonText="Not using Gmail?"
              twPressable="absolute right-0"
            />
            <ButtonTextCustomOrangeIonicons
              onPress={() => {}}
              buttonText="Go to Forum "
              twPressable="absolute left-0"
              iconName="open-outline"
              iconSize={18}
            />
          </View>
        </View>
        {/* End Login Inner Container */}
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
}
