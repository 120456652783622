import "react-native-url-polyfill/auto";
import { useState, useEffect } from "react";
import { supabase } from "./utils/supabase";
import AuthPage from "./components/AuthPage";
import { SafeAreaView, SafeAreaProvider } from "react-native-safe-area-context";
import { Session } from "@supabase/supabase-js";
import { TailwindProvider } from "tailwindcss-react-native";
import { Platform, useColorScheme as useColorSchemeSystem } from "react-native";
import ProfilePage from "./components/ProfilePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFoundPage from "./components/NotFoundPage";
import ContactPage from "./components/ContactPage";
import SignUpEmailPage from "./components/SignUpEmailPage";
import ToastDefault from "./components/Toast/ToastDefault";
import useCachedResources from "./hooks/useCacheResources";

export default function App() {
  const isLoadingComplete = useCachedResources(); // from expo template with navigation
  const colorSchemeSystem = useColorSchemeSystem();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <>
        <TailwindProvider initialColorScheme={colorSchemeSystem}>
          <SafeAreaProvider>
            <MainApp />
          </SafeAreaProvider>
        </TailwindProvider>
        <ToastDefault
          ref={(ref: any) => (global["toast"] = ref)}
          isScrollable={true}
          isRoot={true}
        />
      </>
    );
  }
}

function Home({ session }: { session: Session | null }) {
  return (
    <SafeAreaView className="flex h-full bg-orange-700 dark:bg-gray-800">
      {session && session.user ? (
        <ProfilePage key={session.user.id} session={session} />
      ) : (
        <AuthPage />
      )}
    </SafeAreaView>
  );
}

function MainApp() {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    setSession(supabase.auth.session());

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  if (Platform.OS !== "web") {
    return <Home session={session} />;
  } else {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home session={session} />}></Route>
          <Route path="/email-signup" element={<SignUpEmailPage />}></Route>
          <Route path="/contact-us" element={<ContactPage />}></Route>
          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </Router>
    );
  }
}
