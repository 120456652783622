import { useState, useEffect } from "react";
import {
  ActivityIndicator,
  Image as RnImage,
  View,
  Platform,
} from "react-native";
import { supabase } from "../../utils/supabase";
import * as ImagePicker from "expo-image-picker";
import { ButtonPrimary } from "../Buttons";
import { decode } from "base64-arraybuffer";
import { Ionicons } from "@expo/vector-icons";
import AvatarCropperModal from "./AvatarCropperModalWeb";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import { useColorScheme } from "tailwindcss-react-native";

// note url here is actually the fileName
export default function Avatar({ url, onUpload }: any) {
  const { colorScheme } = useColorScheme();

  const [avatarBase64Url, setAvatarBase64Url] = useState<any>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    if (!url) {
      setDownloading(true);
      setTimeout(() => {
        setDownloading(false); // after timer
      }, 500);
    } else {
      downloadImage(url);
    }
  }, [url]);

  async function downloadImage(path: string) {
    setDownloading(true);
    try {
      const { data, error } = await supabase.storage
        .from("avatars")
        .download(path);
      if (error) {
        throw error;
      }
      // console.log("avatar data:", JSON.stringify(data)); // return Blob data, need to stringify the JSON object before logging it to the console.
      // TODO if image is public, can technically just get the publicURL
      if (data) {
        // react native cannot read Blob data directly, hence we need to use FileReader()
        const fileReaderInstance = new FileReader();
        fileReaderInstance.readAsDataURL(data);
        fileReaderInstance.onload = () => {
          setAvatarBase64Url(fileReaderInstance.result);
        };
        // const url = URL.createObjectURL(data); // this does not work in react native
        // setAvatarUrl(url);
      }
    } catch (error: any) {
      // console.log("Error downloading image: " + error.message);
      toast.show("Error downloading image: " + error.message, {
        type: "danger",
      });
    } finally {
      setDownloading(false);
    }
  }

  // // [we use decode instead, although the code below also worked]
  // const generateImageFromUriBase64 = async (uri: string) => {
  //   const response = await fetch(uri);
  //   const blob = await response.blob();
  //   return blob;
  // };
  // // calling
  // const imageFile = await generateImageFromUriBase64(pickerResult.uri);

  async function uploadAvatar() {
    // permissions for ios and android only
    let permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      toast.show("Permission to access camera roll is required!", {
        type: "danger",
      });
      return;
    }

    //  all, here we limit type of file and do some other config
    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images, // images only, videos not allowed ; if want to support animated GIF quality needs to be undefined and allowsEditing to false, else will take first frame of GIF
      allowsEditing: true, // show a UI to edit the image after it is picked. On Android the user can crop and rotate the image and on iOS simply crop it.
      //aspect: [4, 3], // specifying the aspect ratio to maintain if the user is allowed to edit the image. This is only applicable on Android, since on iOS the crop rectangle is always a square.
      quality: 1, // 0 means compress for small size, 1 means compress for maximum quality.(does not change much - wait for this https://github.com/supabase/supabase/discussions/1407)
      base64: true, // include the image data in Base64 format.
    });

    if (pickerResult.cancelled === true) {
      return;
    }

    // pickerResult.uri
    // WEB => "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB2YA..............gBEqgBEqgBEqgBK5B4P8A507FYbGpoiIAAAAASUVORK5CYII="
    // IOS => "file:///var/mobile/Containers/Data/Application/...../Library/Caches/ExponentExperienceData/expoProjectName.../ImagePicker/36CC4061-8D0C-4966-98FB-B1C25FDA04D1.jpg"
    // ANDROID => "file:///data/user/0/host.exp.exponent/cache/ExperienceData/expoProjectName.../ImagePicker/c680b437-3db2-43cd-a6bb-40aea359bb60.jpg"
    try {
      setUploading(true);

      let base64ImageString = pickerResult.base64 ?? "";
      // console.log("my base 64", base64ImageString);
      let fileExt = "";
      if (Platform.OS === "web") {
        // base64FileData = pickerResult.uri.split(",")[1];
        fileExt = pickerResult.uri.split(";")[0].split("/")[1]; // https://thewebdev.info/2021/09/05/how-to-get-the-extension-of-an-base64-image-with-javascript/

        // if SVG in web, we convert to png
        if (fileExt === "svg+xml") {
          const manipResult = await manipulateAsync(pickerResult.uri, [], {
            // base64: true,
            // compress: 1, // 1 => no compression
            format: SaveFormat.PNG,
          });
          if (manipResult.base64) {
            fileExt = "png";
            base64ImageString = manipResult.base64;
          } else {
            return;
          }
        }
      } else {
        // if android or ios, we need to convert file to base64 [ we actually no longer need this, because we enable expo-image-picker to include the base64 format for all ]
        // base64FileData = await FileSystem.readAsStringAsync(pickerResult.uri, {
        //   encoding: "base64",
        // });
        fileExt = pickerResult.uri.split(".").pop() ?? ""; // last item
      }

      const passFileSizeValidation = handleFileSizeValidation(
        base64ImageString,
        fileExt
      );
      // check file size
      if (!passFileSizeValidation) {
        setUploading(false);
        toast.show("File cannot be more than 10MB!", { type: "danger" });
        return;
      }
      if (url) {
        // if we already have an image url in the database, we perform update instead of upload
        const { error: errorUpdating } = await supabase.storage
          .from("avatars")
          .update(url, decode(base64ImageString), {
            cacheControl: "3600",
            upsert: false,
            contentType: `image/${fileExt}`,
          });

        if (errorUpdating) {
          throw errorUpdating;
        }

        // Math.random() return value between 0.000000001... to 0.9999999999..., we want to remove "0.", we use slice(2)
        const newFileName = `${Math.random().toString().slice(2)}_${new Date()
          .toJSON() // 2022-07-27T05:57:28.465Z
          .replace(/\.|:/g, "-")}`; // regex replace all occurence of period "\." OR ":" (period need to be \)

        const newFilePath = `${newFileName}.${fileExt}`;

        // we copy file to change the name of the newFile
        const { error: errorCopying } = await supabase.storage
          .from("avatars")
          .copy(url, newFilePath);

        if (errorCopying) {
          throw errorCopying;
        }

        // we delete the old file with the old name
        const { error: errorDeleting } = await supabase.storage
          .from("avatars")
          .remove([url]);

        if (errorDeleting) {
          throw errorDeleting;
        }

        onUpload(newFilePath); // [no longer the case] if we send the same filePath, it will not re-render, we need to force refresh
      } else {
        // Math.random() return value between 0.000000001... to 0.9999999999..., we want to remove "0.", we use slice(2)
        const fileName = `${Math.random().toString().slice(2)}_${new Date()
          .toJSON() // 2022-07-27T05:57:28.465Z
          .replace(/\.|:/g, "-")}`; // regex replace all occurence of period "\." OR ":" (period need to be \)

        const filePath = `${fileName}.${fileExt}`; // include folder if any, here we don't have a dedicated folder, we just have bucket

        // decode(base64FileData) is type of ArrayBuffer which is accepted by supabase storage in July 2021 (https://github.com/supabase/supabase/issues/1257)
        let { error: uploadError } = await supabase.storage
          .from("avatars")
          .upload(filePath, decode(base64ImageString), {
            contentType: `image/${fileExt}`,
          });
        if (uploadError) {
          throw uploadError;
        }
        onUpload(filePath);
      }
    } catch (error: any) {
      toast.show(error.message, { type: "danger" });
    } finally {
      setUploading(false);
    }
  }

  // https://stackoverflow.com/questions/29939635/how-to-get-file-size-of-newly-created-image-if-src-is-base64-string
  function handleFileSizeValidation(
    base64ImageString: string,
    fileExt: string
  ): boolean {
    var stringLength =
      base64ImageString.length - `data:image/${fileExt};base64,`.length;
    var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    var sizeInMB = sizeInBytes / 1048576;
    // console.log("photo size", sizeInMB);
    if (sizeInMB <= 10) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <View className="items-center justify-center flex-1">
      {downloading ? (
        <View className="items-center justify-center flex w-[150px] h-[150px] rounded">
          {colorScheme === "dark" ? (
            <ActivityIndicator color="white" />
          ) : (
            <ActivityIndicator />
          )}
        </View>
      ) : avatarBase64Url ? (
        <View className="relative w-[150px] h-[150px]">
          <RnImage
            source={{ uri: avatarBase64Url }}
            style={{
              width: 150,
              height: 150,
              resizeMode: "cover",
              backgroundColor: "white",
              alignItems: "center",
              borderRadius: 4,
            }}
          />
          {Platform.OS === "web" ? (
            <View className="absolute top-0 right-0 -mr-9">
              <AvatarCropperModal
                origImageBase64Url={avatarBase64Url}
                origImageName={url}
                onUpload={onUpload}
              />
            </View>
          ) : null}
        </View>
      ) : (
        <View className="items-center justify-center flex w-[150px] h-[150px]">
          {colorScheme === "dark" ? (
            <Ionicons name="person-outline" size={50} color="white" />
          ) : (
            <Ionicons name="person-outline" size={50} color="black" />
          )}
          {/* <Text>No Image</Text> */}
        </View>
      )}

      <ButtonPrimary
        onPress={uploadAvatar}
        buttonText="Upload Public Avatar"
        isDisabled={uploading}
        loading={uploading}
        widthTailwind="w-40"
        marginTailwind="my-4"
      />
    </View>
  );
}
