// two methods for SVG in react native
// https://blog.openreplay.com/working-with-svgs-in-react-native (include example with animated svg)
// this one with https://react-svgr.com/playground/?native=true
// or via react-native-svg-transformer library (tried but did not work)
import Svg, { Path } from "react-native-svg";

const Logo = (props) => (
  <Svg
    viewBox="0 0 1417 1413"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <Path
      d="M1099.64 1334.45c-45.471 51.021-188.213 60.658-318.421 65.712-27.467 1.059-56.738 2.063-84.6 2.063h-.021c-208.038 0-396.508-45.363-417.742-592.984-4.791-123.3 29.654-223.82 102.396-298.787 68.958-66.75 153.421-96.421 212.204-109.617 57.692-12.971 105.784-13.596 126.321-13.854 2.813-.033 5.017-.033 6.563-.087l139.737-5.45c-1.542-46.53-5.171-231.58 31.633-294.705C934.585 23.546 1004.223 10.3 1056.131 10.3c40.521 0 71.734 7.95 73.054 8.279 4.775 1.233 8.038 5.729 7.725 10.679l-24.879 391.996 25.784 663.975c5.191 133.421 5.762 199.808-38.18 249.221h.005Zm-232.984-321.6c-3.404-88.263-68.475-123.625-131.408-146.492-69.113-26.283-81.163-40.696-82.029-63.108-.28-6.65.795-19.321 10.883-30.034 9.671-10.312 25.625-16.179 46.129-16.995l6.771-.105c38.7 0 63.733 13.055 77.187 20.067l19.913 10.383 28.75-85.816-14.271-7.654c-25.762-13.821-54.096-21.617-87.133-23.7l-2.796-72.588-84.6 3.267 2.983 77.083c-66.837 19.721-107.566 72.5-105.016 138.158 3.195 82.259 72.05 117.221 138.837 140.45 63.454 22.884 72.988 45.713 73.821 66.93 1.212 31.595-25.696 53.941-67.017 55.537l-6.146.104c-30.779 0-63.87-9.254-88.437-24.721l-21.042-13.3-27.533 87.484 12.1 7.933c26.267 17.258 65.521 28.646 105.017 30.85l2.916 75.313 84.621-3.35-3.108-80.017c69.446-20.488 113.3-77.346 110.608-145.679Z"
      style={{
        fill: "#f15a24",
        fillRule: "nonzero",
      }}
    />
  </Svg>
);

export default Logo;
