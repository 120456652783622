import { useColorScheme } from "tailwindcss-react-native";
import { useState } from "react";
import { Pressable, View } from "react-native";
// import { Ionicons } from "@expo/vector-icons";
import Ionicons from "../Svg/Ionicons";
import { useModalStore } from "../../utils/store";
import SupportModal from "../ContactPage/SupportModal";

export default function SupportButtonModal() {
  const { colorScheme } = useColorScheme();
  const [modalVisible, setModalVisible] = useState(false);

  // ZUSTAND
  const setModalShowing = useModalStore((state) => state.setModalShowing);

  return (
    <>
      <SupportModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setModalShowing={setModalShowing}
      />
      <View className="absolute top-5 left-5">
        <Pressable
          className="flex items-center justify-center bg-gray-100 rounded dark:bg-grayish-500 w-11 h-11"
          onPress={() => {
            setModalVisible(true);
            setModalShowing(true);
          }}
        >
          {colorScheme === "dark" ? (
            <Ionicons name="logo-whatsapp-white-5" size={20} color="white" />
          ) : (
            <Ionicons name="logo-whatsapp-black-5" size={20} color="black" />
          )}
        </Pressable>
      </View>
    </>
  );
}
