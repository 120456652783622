import { useState, useRef, useEffect } from "react";
import {
  StyleSheet,
  Text,
  Pressable,
  View,
  ActivityIndicator,
  Platform,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { Motion } from "@legendapp/motion/";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import Timer from "../FormHelper/Timer";
import {
  handleCheckUsername,
  handleRequestOtp,
  handleVerifyOtp,
} from "../AuthPage/authFunctions";
import {
  emailRules,
  firstLastNameRules,
  passwordRules,
  phoneNumberRules,
  usernameRules,
} from "../FormHelper/formRules";
import { supabase } from "../../utils/supabase";
import AlertModal from "../AlertModal";
import { ButtonPrimaryCustom } from "../Buttons";
import { useModalStore } from "../../utils/store";
import DarkSwitchButton from "../Buttons/DarkSwitchButton";
import SupportButtonModal from "../Buttons/SupportButtonModal";
import Logo from "../Svg/LogoDealsifu";
import PickerAgeRange from "../FormHelper/PickerAgeRange";
import { Ionicons } from "@expo/vector-icons";
import PrivacyNotice from "../FormHelper/PrivacyNotice";
import { useNavigate } from "react-router-dom"; // for web only
import * as Linking from "expo-linking";
import GenderSwitchSelecter from "../FormHelper/GenderSwitchSelector";

export default function SignUpEmailPage() {
  const navigate = Platform.OS === "web" ? useNavigate() : null;

  const [selectedAgeRange, setSelectedAgeRange] = useState<string>("");
  const [isGenderSelected, setIsGenderSelected] = useState<boolean>(false);
  const [genderSwitch, setGenderSwitch] = useState<number>(0);
  const [selectedGender, setSelectedGender] = useState<string>("");

  const [modalAlertVisible, setModalAlertVisible] = useState(false);

  const [loadingEmailSU, setLoadingEmailSU] = useState(false);
  const [loadingCheckUsername, setLoadingCheckUsername] = useState(false);
  const [loadingRequestOtp, setLoadingRequestOtp] = useState(false);
  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false);

  const [usernameValidated, setUsernameValidated] = useState(false);
  const [usernameTextRed, setUsernameTextRed] = useState(false);
  const [phoneValidated, setPhoneValidated] = useState(false);

  // ZUSTAND
  const setModalShowing = useModalStore((state) => state.setModalShowing);

  // helper for Alert Modal
  const handleAlertModalExit = () => {
    setPhoneValidated(false);
    setUsernameValidated(false);
    reset(); // reset the form, for now we don't reset gender and age_range
    setModalAlertVisible(false);
    setModalShowing(false);
    // move back home
    if (Platform.OS === "web") {
      if (navigate) {
        navigate("/"); // this also result in theme following system theme instead of the dark switch button
      }
    } else {
      Linking.openURL("/"); // this will result in theme following system theme instead of the dark switch button, is it even possible to go to 404 for mobile app?
    }
  };

  const [displayPinPad, setDisplayPinPad] = useState(false);
  const [mvdRequestId, setMvdRequestId] = useState<string>("");
  const [otpValue, setOtpValue] = useState("");
  const [codeFieldProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value: otpValue,
    setValue: setOtpValue,
  });

  const CELL_COUNT = 4; // our OTP is 4-digit long
  const TIMER_SECONDS = 180 + 10; // 3 mins plus 10 seconds buffer

  const codeFieldRef = useBlurOnFulfill({
    value: otpValue,
    cellCount: CELL_COUNT,
  });

  const [isShowingTimer, setIsShowingTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(TIMER_SECONDS);

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmedPassword: "",
    },
  });

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    otpValue.length === CELL_COUNT &&
      handleVerifyOtp(
        otpValue,
        mvdRequestId,
        getValues,
        null, // toastRef,
        setLoadingVerifyOtp,
        setPhoneValidated,
        setMvdRequestId,
        setOtpValue,
        setDisplayPinPad
      );
  }, [otpValue]);

  // data from react-hook-form
  // data =  {email: "" , password: "", ... }
  async function signUpWithEmail({
    username,
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    confirmedPassword,
  }: any) {
    // should never happen because react-hook-form check this, but just in case
    if (password !== confirmedPassword) {
      toast.show("Password does not match!");
      return;
    }
    if (
      selectedAgeRange === "Please select an option..." ||
      selectedAgeRange === ""
    ) {
      toast.show("Age Range selection is required.", { type: "danger" });
      return;
    }
    if (!isGenderSelected) {
      toast.show("Gender is required.", { type: "danger" });
      return;
    }

    setLoadingEmailSU(true);
    const { user, error } = await supabase.auth.signUp(
      {
        email,
        password,
      },
      {
        //redirectTo: "https://dealsifu-web.vercel.app/", // "http://localhost:19006", // after redirect no longer has control of current page context
        data: {
          email,
          full_name: firstName + " " + lastName,
          first_name: firstName,
          last_name: lastName,
          username,
          phone_num: phoneNumber,
          age_range: selectedAgeRange,
          gender: selectedGender,
        },
      }
    );
    if (error) alert(error.message);

    if (user) {
      // console.log("my user", user);
      // alert("User registration is successful. Please verify your email.");
      setModalAlertVisible(true);
      setModalShowing(true);
    }
    setLoadingEmailSU(false);
  }

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS !== "web" ? Keyboard.dismiss : () => {}}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        className="flex-col items-center justify-center h-full p-10 bg-white dark:bg-grayish-700"
      >
        <DarkSwitchButton />
        <SupportButtonModal />
        <Pressable
          onPress={() => {
            if (Platform.OS === "web") {
              if (navigate) {
                navigate("/"); // this also result in theme following system theme instead of the dark switch button
              }
            } else {
              Linking.openURL("/"); // this will result in theme following system theme instead of the dark switch button, is it even possible to go to 404 for mobile app?
            }
          }}
        >
          <Logo className="w-16 h-16 mb-6" />
        </Pressable>

        <View className="relative flex-col items-center justify-center p-5 bg-gray-100 rounded w-80 dark:bg-grayish-800">
          {/* Touch area below is impacted by the padding set above */}
          <View className="absolute top-2 left-1">
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === "web") {
                  if (navigate) {
                    navigate("/");
                  }
                } else {
                  Linking.openURL("/");
                }
              }}
            >
              <Ionicons name="chevron-back" size={28} color="gray" />
            </TouchableOpacity>
          </View>

          <View className="w-full">
            <Text className="mb-6 text-xl font-semibold text-center text-gray-900 dark:text-white">
              Sign Up
            </Text>
            <View className="flex-row">
              <Ionicons
                name="information-circle-outline"
                size={15}
                color="#ef4444"
              />
              <Text className="mb-3 ml-1 text-xs italic text-black dark:text-white">
                This sign up will require email verification. We recommend{" "}
                <Pressable
                  className="font-semibold"
                  onPress={() => {
                    Linking.openURL("/");
                  }}
                >
                  <Text>"Sign up with Google"</Text>
                </Pressable>{" "}
                for Gmail users. One less password to remember!
              </Text>
            </View>
            <Text className="mb-1 text-orange-500">Username</Text>
            <Controller
              control={control}
              rules={usernameRules}
              render={({ field: { onChange, onBlur, value } }) => (
                <View className="flex-row items-center w-full p-1.5 mb-5 bg-gray-200 dark:bg-grayish-600 rounded">
                  <View className="basis-3/4">
                    <TextInput
                      className={
                        usernameTextRed
                          ? "text-red-500 bg-transparent caret-black"
                          : "bg-transparent caret-black text-black dark:text-white"
                      }
                      style={Platform.select(rnw_outline_none)}
                      onBlur={onBlur}
                      onChangeText={(e) => {
                        onChange(e);
                        if (usernameValidated === true) {
                          setUsernameValidated(false);
                        }
                        if (usernameTextRed === true) {
                          setUsernameTextRed(false);
                        }
                      }}
                      value={value}
                      keyboardType={"default"}
                      placeholder={"Choose a username"}
                      placeholderTextColor="gray"
                    />
                  </View>
                  <View className="px-1 py-1 bg-gray-300 rounded basis-1/4">
                    {loadingCheckUsername ? (
                      <View className="flex items-center justify-center py-2">
                        <ActivityIndicator className="absolute" color="black" />
                      </View>
                    ) : usernameValidated ? (
                      <Text className="text-xs text-center">OK ✅</Text>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          handleCheckUsername(
                            getValues,
                            trigger,
                            null, // toastRef,
                            setLoadingCheckUsername,
                            setUsernameTextRed,
                            setUsernameValidated
                          );
                        }}
                      >
                        <Text className="text-xs text-center">check</Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              )}
              name="username"
            />
            {errors.username && (
              <Text className="mb-5 -mt-4 text-red-700 dark:text-red-100">
                {errors.username.message}
              </Text>
            )}

            <View className="flex-row">
              <View className="basis-[47%]">
                <Text className="mb-1 text-orange-500">First Name*</Text>
                <Controller
                  control={control}
                  rules={firstLastNameRules}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      className="p-2 mb-5 bg-gray-200 rounded caret-black dark:bg-grayish-600 dark:text-white"
                      style={Platform.select(rnw_outline_none)}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      keyboardType={"default"}
                      placeholder={"First name"}
                      placeholderTextColor="gray"
                    />
                  )}
                  name="firstName"
                />
                {errors.firstName && (
                  <Text className="mb-5 -mt-4 text-red-700 dark:text-red-100">
                    {errors.firstName.message}
                  </Text>
                )}
              </View>

              <View className="basis-[6%]"></View>

              <View className="basis-[47%]">
                <Text className="mb-1 text-orange-500">Last Name*</Text>
                <Controller
                  control={control}
                  rules={firstLastNameRules}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      className="p-2 mb-5 bg-gray-200 rounded caret-black dark:bg-grayish-600 dark:text-white"
                      style={Platform.select(rnw_outline_none)}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      keyboardType={"default"}
                      placeholder={"Last name"}
                      placeholderTextColor="gray"
                    />
                  )}
                  name="lastName"
                />
                {errors.lastName && (
                  <Text className="mb-5 -mt-4 text-red-700 dark:text-red-100">
                    {errors.lastName.message}
                  </Text>
                )}
              </View>
            </View>

            <Text className="mb-1 text-orange-500">Email*</Text>
            <Controller
              control={control}
              rules={emailRules}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  className="p-2 mb-5 bg-gray-200 rounded caret-black dark:bg-grayish-600 dark:text-white"
                  style={Platform.select(rnw_outline_none)}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  keyboardType={"default"}
                  placeholder={"Enter email"}
                  placeholderTextColor="gray"
                />
              )}
              name="email"
            />
            {errors.email && (
              <Text className="mb-5 -mt-4 text-red-700 dark:text-red-100">
                {errors.email.message}
              </Text>
            )}

            <Text className="mb-1 text-orange-500">Phone Number*</Text>
            <Controller
              control={control}
              rules={phoneNumberRules}
              render={({ field: { onChange, onBlur, value } }) => (
                <View className="flex-row items-center w-full p-1.5 mb-5 bg-gray-200 rounded dark:bg-grayish-600">
                  <View className="basis-3/4">
                    <TextInput
                      className={
                        phoneValidated
                          ? "text-gray-400 bg-transparent"
                          : "text-black bg-transparent caret-black dark:text-white"
                      }
                      style={Platform.select(rnw_outline_none)}
                      onBlur={onBlur}
                      onChangeText={(e) => {
                        onChange(e.replace(/[^0-9]/g, "")); // we want digits only
                      }}
                      value={value}
                      keyboardType={"numeric"}
                      placeholder={"Malaysian mobile only"}
                      placeholderTextColor="gray"
                      editable={!phoneValidated}
                      selectTextOnFocus={!phoneValidated}
                    />
                  </View>
                  <View className="px-1 py-1 bg-gray-300 rounded basis-1/4">
                    {loadingRequestOtp ? (
                      <View className="flex items-center justify-center py-2">
                        <ActivityIndicator className="absolute" color="black" />
                      </View>
                    ) : phoneValidated ? (
                      <Text className="text-xs text-center">OK ✅</Text>
                    ) : !isShowingTimer ? (
                      <TouchableOpacity
                        onPress={() => {
                          handleRequestOtp(
                            getValues,
                            trigger,
                            null, // toastRef,
                            setLoadingRequestOtp,
                            setPhoneValidated,
                            setMvdRequestId,
                            setDisplayPinPad,
                            setIsShowingTimer
                          );
                        }}
                      >
                        <Text className="text-xs text-center">verify</Text>
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity onPress={() => {}} disabled>
                        <Timer
                          setIsShowingTimer={setIsShowingTimer}
                          timeLeft={timeLeft}
                          setTimeLeft={setTimeLeft}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              )}
              name="phoneNumber"
            />
            {errors.phoneNumber && (
              <Text className="mb-5 -mt-4 text-red-700 dark:text-red-100">
                {errors.phoneNumber.message}
              </Text>
            )}

            {displayPinPad ? (
              <Motion.View
                key="A"
                className={Platform.OS === "android" ? "mb-1" : "mb-5"}
                initial={{ y: 0 }}
                animate={{ y: 5 }}
                transition={{
                  type: "spring",
                  damping: 20,
                  stiffness: 1000,
                  mass: 2,
                }}
              >
                <Motion.View className="flex-row items-center">
                  <Motion.Text className="mb-1 mr-2 -mt-2 text-orange-500">
                    SMS Verification Code
                  </Motion.Text>
                  {loadingVerifyOtp && (
                    <ActivityIndicator className="-mt-4" color="gray" />
                  )}
                </Motion.View>

                <CodeField
                  ref={codeFieldRef}
                  {...codeFieldProps}
                  // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
                  value={otpValue}
                  onChangeText={setOtpValue}
                  cellCount={CELL_COUNT}
                  keyboardType="numeric"
                  textContentType="oneTimeCode"
                  renderCell={({ index, symbol, isFocused }) => (
                    <Text
                      key={index}
                      style={[styles.cell, isFocused && styles.focusCell]}
                      onLayout={getCellOnLayoutHandler(index)}
                    >
                      {symbol || (isFocused ? <Cursor /> : null)}
                    </Text>
                  )}
                />
                <Text className="mt-2 text-xs italic text-justify text-gray-500 dark:text-white">
                  TUNETALK users, please contact{" "}
                  {Platform.OS === "web" ? (
                    <Pressable
                      className="font-bold underline"
                      onPress={() => {
                        window.open(
                          "https://dealsifu.com/contact-us",
                          "_blank"
                        );
                      }}
                    >
                      <Text>support</Text>
                    </Pressable>
                  ) : (
                    <Text>support.</Text>
                  )}{" "}
                  first. SMS will come from MOVIDER. The countdown is just an
                  estimation.
                </Text>
              </Motion.View>
            ) : null}

            <Text className="mb-1 text-orange-500">Age Range*</Text>
            <View className="mb-5">
              <PickerAgeRange
                selectedAgeRange={selectedAgeRange}
                setSelectedAgeRange={setSelectedAgeRange}
              />
            </View>

            <Text className="mb-1 text-orange-500">Gender*</Text>
            <GenderSwitchSelecter
              isGenderSelected={isGenderSelected}
              setIsGenderSelected={setIsGenderSelected}
              genderSwitch={genderSwitch}
              setGenderSwitch={setGenderSwitch}
              selectedGender={selectedGender}
              setSelectedGender={setSelectedGender}
              startX={26}
              endX={138}
            />

            <Text className="mb-1 text-orange-500">Password*</Text>
            <Controller
              control={control}
              rules={passwordRules}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  className="p-2 mb-5 bg-gray-200 rounded caret-black dark:bg-grayish-600 dark:text-white"
                  style={Platform.select(rnw_outline_none)}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  secureTextEntry={true}
                  placeholder={"Create password"}
                  placeholderTextColor="gray"
                />
              )}
              name="password"
            />
            {errors.password && (
              <Text className="mb-5 -mt-4 text-red-700 dark:text-red-100">
                {errors.password.message}
              </Text>
            )}

            <Text className="mb-1 text-orange-500">Confirm Password*</Text>
            <Controller
              control={control}
              rules={{
                validate: (value) =>
                  value === password.current || "The passwords do not match",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  className="p-2 mb-5 bg-gray-200 rounded caret-black dark:bg-grayish-600 dark:text-white"
                  style={Platform.select(rnw_outline_none)}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  secureTextEntry={true}
                  placeholder={"Confirm password"}
                  placeholderTextColor="gray"
                />
              )}
              name="confirmedPassword"
            />
            {errors.confirmedPassword && (
              <Text className="mb-5 -mt-4 text-red-700 dark:text-red-100">
                {errors.confirmedPassword.message}
              </Text>
            )}

            <ButtonPrimaryCustom
              onPress={handleSubmit(signUpWithEmail)}
              isDisabled={
                loadingEmailSU || !usernameValidated || !phoneValidated
              }
              loading={loadingEmailSU}
              buttonText="Sign up with Email"
              customTailwind={
                usernameValidated && phoneValidated
                  ? "w-full py-3 bg-orange-600 rounded hover:bg-orange-700 active:bg-orange-800"
                  : "w-full py-3 bg-gray-400 rounded"
              }
            />
            <PrivacyNotice />
          </View>
        </View>
        {/* End Login Inner Container */}
        <AlertModal
          modalAlertVisible={modalAlertVisible}
          setModalAlertVisible={setModalAlertVisible}
          handleAlertModalExit={handleAlertModalExit}
          textPrimary="User registration is successful."
          textSecondary="Please check your email for verification."
        />
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  modalView: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  cell: {
    width: 40,
    height: 40,
    lineHeight: 32, // initially 38
    fontSize: 24,
    fontWeight: "300",
    textAlign: "center",
    borderWidth: 2,
    borderColor: "#9ca3af", // bg-gray-400
    backgroundColor: "#e5e7eb", // bg-gray-200
  },
  focusCell: {
    borderColor: "#f97316", // bg-orange-500
  },
});

// workaround, for react-native-web by default, it has border outline around TextInput when focused
// https://stackoverflow.com/questions/53639013/typescript-and-react-native-are-the-type-definitions-for-rn-styles-wrong
const rnw_outline_none: any = {
  web: {
    outlineStyle: "none",
  },
};
