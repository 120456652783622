import create from "zustand";

interface DiscourseState {
  redirectUrl: string;
  setRedirectUrl: (url: string) => void;
}

export const useDiscourseStore = create<DiscourseState>((set) => ({
  redirectUrl: "",
  setRedirectUrl: (url) => set({ redirectUrl: url }),
}));

// we need this due to safari browser notch background color behavior
interface ModalState {
  modalShowing: boolean;
  setModalShowing: (toShow: boolean) => void;
  modalTwoShowing: boolean;
  setModalTwoShowing: (toShow: boolean) => void;
}

export const useModalStore = create<ModalState>((set) => ({
  modalShowing: false,
  setModalShowing: (toShow) => set({ modalShowing: toShow }),
  modalTwoShowing: false,
  setModalTwoShowing: (toShow) => set({ modalTwoShowing: toShow }),
}));
